import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  keyframes,
  Image,
  Flex,
  Stack,
  StackItem,
  Icon,
  HStack,
} from '@chakra-ui/react'
import { DebtContext } from 'context/DebtContext'
import { format, parseISO } from 'date-fns'
import React, { useContext, useEffect } from 'react'
import { mask } from 'utils/remask'
import { sendAnalytics } from 'utils/sendAnalytics'

import Algar from '../../assets/algar-logo.svg'
import Eudora from '../../assets/eudora-logo.svg'
import oBoticario from '../../assets/o-boticario-logo.svg'
import Viacerta from '../../assets/viacerta-logo.svg'
import Natura from '../../assets/natura-logo.svg'
import Bradesco from '../../assets/bradesco.svg'
import Credsystem from '../../assets/credsystem.svg'
import { FiMail, FiPhone } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import { formatPhone } from 'utils/formatPhone'
import { AuthContext } from 'context/AuthContext'

const fade = keyframes`
  from { opacity: 0; transform: translateY(-10px) }
  to { opacity: 1; transform: translateY(0px) }
`
type Contract = {
  dueDate: string
  latePaymentDays: number
  contract: string
  product: string
  company: string
  currentValue: string
  totalValue: string
  discountValue: string
  titleNumber: string
}

interface Debt {
  totalValue: number
  discountValue: number
  discount: number
  qtyContracts: number
  walletInfo: {
    phone: string
    whatsapp: string
    email: string
  }
  data: unknown
  contracts: Contract[]
  client: string
  idAcao: string
  id: string
  dueDate: string | Date
}

type WalletInfoModalProps = {
  isOpen?: boolean
  onClose: () => void
  debt: Debt | null
}

function WalletInfoModal({
  isOpen,
  onClose,
  debt,
}: WalletInfoModalProps): JSX.Element {
  const { statusCode, demoMode } = useContext(DebtContext)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (!debt || !user) return
    const { id, actionId } = user
    if (isOpen) {
      sendAnalytics({
        id: id,
        actionId: actionId,
        step: 'VER TITULOS',
        status: 'OK',
        statusCode,
        isDemo: demoMode,
      })
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      size="xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent marginX={['1rem', '1rem']} p={['0.5rem', '1rem']}>
        <ModalCloseButton />
        <ModalBody pb="1rem">
          <Flex
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            direction="column"
            py="1rem"
          >
            <Text
              fontSize={['1.35rem', '1.75rem']}
              fontWeight="semibold"
              marginBottom="2.5rem"
            >
              Entre em contato conosco!
            </Text>
            {debt?.client === 'natura' && (
              <Image
                src={Natura}
                w="100%"
                maxW="110px"
                objectFit="contain"
                alt="natura"
              />
            )}
            {debt?.client === 'boticario' && (
              <Image
                src={oBoticario}
                w="100%"
                maxW="160px"
                objectFit="contain"
                alt="oboticario"
              />
            )}
            {debt?.client === 'algar' && (
              <Image
                src={Algar}
                alt="algar"
                w="100%"
                maxW="150px"
                objectFit="contain"
              />
            )}
            {debt?.client === 'eudora' && (
              <Image
                src={Eudora}
                alt="eudora"
                w="100%"
                maxW="110px"
                objectFit="contain"
              />
            )}
            {debt?.client === 'viacerta' && (
              <Image
                src={Viacerta}
                alt="viacerta"
                w="100%"
                maxW="160px"
                objectFit="contain"
              />
            )}
            <Stack
              direction={['column', 'row']}
              marginTop="2.5rem"
              spacing="2rem"
            >
              <StackItem>
                <Flex alignItems="center">
                  <Icon as={FiPhone} marginRight="7px" color="#727272" />
                  <Text color="#727272">telefone</Text>
                </Flex>
                {debt?.walletInfo.phone ? (
                  <Text
                    fontWeight="medium"
                    color="#333"
                    as="a"
                    href={`tel:${debt?.walletInfo.phone}`}
                  >
                    {formatPhone(debt?.walletInfo.phone)}
                  </Text>
                ) : (
                  <Text fontWeight="light" color="#727272">
                    sem telefone
                  </Text>
                )}
              </StackItem>
              <StackItem>
                <Flex alignItems="center">
                  <Icon as={FaWhatsapp} marginRight="7px" color="#727272" />
                  <Text color="#727272">whatsapp</Text>
                </Flex>
                {debt?.walletInfo.whatsapp ? (
                  <Text
                    fontWeight="medium"
                    color="#333"
                    as="a"
                    href={`https://wa.me/${debt?.walletInfo.whatsapp}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {formatPhone(debt?.walletInfo.whatsapp)}
                  </Text>
                ) : (
                  <Text fontWeight="light" color="#727272">
                    sem whatsapp
                  </Text>
                )}
              </StackItem>
              <StackItem>
                <Flex alignItems="center">
                  <Icon as={FiMail} marginRight="7px" color="#727272" />
                  <Text color="#727272">email</Text>
                </Flex>
                {debt?.walletInfo.email ? (
                  <Text
                    fontWeight="medium"
                    color="#333"
                    as="a"
                    href={`mailto:${debt?.walletInfo.email}`}
                  >
                    {debt?.walletInfo.email}
                  </Text>
                ) : (
                  <Text fontWeight="light" color="#727272">
                    sem email
                  </Text>
                )}
              </StackItem>
            </Stack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default WalletInfoModal
