import { billetApi } from '../services'
import Logo from '../assets/logo-v2.png'

export function errorHandler(
  error: Error,
  info: { componentStack: string }
): void {
  if (error) {
    // billetApi({
    //   method: 'post',
    //   url: '/errors/email/send',
    //   data: {
    //     sender: {
    //       name: 'simplifica.ai',
    //       logo: `${process.env.REACT_APP_FRONTEND}${Logo}`,
    //     },
    //     destination: {
    //       name: 'Maxwell Dev',
    //       email: 'max.macedo@vya.digital',
    //     },
    //     content: {
    //       subject: `Error - SIMPLIFICA.AI`,
    //       errorMessage: error.message || 'No message error',
    //       componentStack: info.componentStack,
    //       platform: 'simplifica.ai',
    //     },
    //   },
    // })
    console.log('[error]', error.message, info.componentStack)
  }
}
