import { toPattern } from 'vanilla-masker'

export const unMask = (value: string): string => value?.replace(/\W/g, '')

const masker = (
  value: string,
  pattern: string,
  options?: { [key: string]: string | number | boolean }
) => toPattern(value, { pattern, ...options })

const multimasker = (
  value: string,
  patterns: string[],
  options?: { [key: string]: string | number | boolean }
) =>
  masker(
    value,
    patterns.reduce(
      (memo, pattern) => (value.length <= unMask(memo).length ? memo : pattern),
      patterns[0]
    ),
    options
  )

export const mask = (
  value: string,
  pattern: string | string[],
  options?: { [key: string]: string | number | boolean }
): string =>
  typeof pattern === 'string'
    ? masker(value, pattern || '', options)
    : multimasker(value, pattern, options)
