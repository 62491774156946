import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
  keyframes,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
} from '@chakra-ui/react'
import { AuthContext } from 'context/AuthContext'
import { DebtContext } from 'context/DebtContext'
import { format, formatDuration, parseISO, intervalToDuration } from 'date-fns'

import { ptBR } from 'date-fns/locale'
import React, { useContext, useEffect } from 'react'
import { formatPhone } from 'utils/formatPhone'
import { mask } from 'utils/remask'
import { sendAnalytics } from 'utils/sendAnalytics'

const fade = keyframes`
  from { opacity: 0; transform: translateY(-10px) }
  to { opacity: 1; transform: translateY(0px) }
`
type Contract = {
  dueDate: string
  latePaymentDays: number
  contract: string
  product: string
  company: string
  currentValue: string
  totalValue: string
  discountValue: string
  titleNumber: string
}

interface Debt {
  totalValue: number
  discountValue: number
  discount: number
  qtyContracts: number
  walletInfo: {
    phone: string
    whatsapp: string
    email: string
  }
  data: unknown
  contracts: Contract[]
  client: string
  idAcao: string
  id: string
  dueDate: string | Date
}

type ContractModalProps = {
  isOpen?: boolean
  onClose: () => void
  debt: Debt | null
}

function ContractModal({
  isOpen,
  onClose,
  debt,
}: ContractModalProps): JSX.Element {
  const { statusCode, demoMode } = useContext(DebtContext)
  const { user } = useContext(AuthContext)
  useEffect(() => {
    if (!user) return
    const { id, actionId } = user
    if (isOpen) {
      sendAnalytics({
        id,
        actionId,
        step: 'VER TITULOS',
        status: 'OK',
        statusCode,
        isDemo: demoMode,
      })
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      size={'6xl'}
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent marginX={['1rem', '1rem']} p={['0.5rem', '1rem']}>
        <ModalHeader>
          <Text color="primary.main" fontSize="3xl" fontWeight="700">
            Contrato - {debt?.contracts[0]?.contract}
          </Text>
          <Text color="secondary" fontSize="md" fontWeight="400">
            veja os títulos de sua dívida
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="1rem">
          <TableContainer>
            <Table variant="simple">
              {debt?.walletInfo.phone && (
                <TableCaption>
                  Dúvidas fale com o nosso time{' '}
                  <Text
                    as="a"
                    href={`tel:${debt?.walletInfo.phone}`}
                    fontWeight="700"
                  >
                    {formatPhone(debt?.walletInfo.phone)}
                  </Text>
                </TableCaption>
              )}
              {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
              <Thead>
                <Tr>
                  {/* <Th>Número Contrato</Th> */}
                  <Th>Número Titulo</Th>
                  <Th>Vencimento</Th>
                  <Th>Valor Original</Th>
                  <Th>Atraso</Th>
                </Tr>
              </Thead>
              <Tbody>
                {debt?.contracts.map((contract, index) => (
                  <Tr key={index}>
                    {/* <Td>{contract.contract}</Td> */}
                    <Td>{contract.titleNumber}</Td>
                    <Td>{format(parseISO(contract.dueDate), 'dd/MM/yyyy')}</Td>
                    <Td>
                      {Number(contract.totalValue).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Td>
                    <Td>
                      {contract.dueDate &&
                        formatDuration(
                          intervalToDuration({
                            start: parseISO(contract.dueDate as string),
                            end: new Date(),
                          }),
                          { format: ['years', 'months', 'days'], locale: ptBR }
                        )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              {/* <Tfoot>
                <Tr>
                  <Th>Número Titulo</Th>
                  <Th>Vencimento</Th>
                  <Th>Valor Original</Th>
                  <Th>Dias em atraso</Th>
                </Tr>
              </Tfoot> */}
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ContractModal
