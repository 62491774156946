import ProposalStep from './Proposal'
import ConfirmationStep from './Confirmation'
import PaymentStep from './Payment'

interface IStep {
  id: string
  component: React.FC<StepComponentProps>
}

export const steps: IStep[] = [
  {
    id: 'proposal-step',
    component: ProposalStep,
  },
  {
    id: 'confirmation-step',
    component: ConfirmationStep,
  },
  {
    id: 'payment-step',
    component: PaymentStep,
  },
]

export interface StepComponentProps {
  onChangeStep: (step: number) => void
  previousStep: () => void
  nextStep: () => void
  customer?: string
}
