import React, { useContext } from 'react'
import { Tooltip, Icon, useDisclosure, IconProps } from '@chakra-ui/react'
import { FiHelpCircle } from 'react-icons/fi'
import ContractModal from '.'
import { DebtContext } from 'context/DebtContext'

interface Contract {
  dueDate: string
  latePaymentDays: number
  contract: string
  product: string
  company: string
  currentValue: string
  totalValue: string
  discountValue: string
  titleNumber: string
}

interface Debt {
  totalValue: number
  discountValue: number
  discount: number
  qtyContracts: number
  walletInfo: {
    phone: string
    whatsapp: string
    email: string
  }
  data: unknown
  contracts: Contract[]
  client: string
  idAcao: string
  id: string
  dueDate: string | Date
}

interface ToggleProps extends IconProps {
  debt: Debt | null
  tooltipText?: string
}

function Toggle({ tooltipText, debt, ...props }: ToggleProps): JSX.Element {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Tooltip
        label={tooltipText || 'Ver títulos'}
        bg="primary.main"
        borderRadius="10px"
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            as={FiHelpCircle}
            fontSize="lg"
            cursor="pointer"
            onClick={onOpen}
            {...props}
          />
        </span>
      </Tooltip>

      <ContractModal isOpen={isOpen} onClose={onClose} debt={debt} />
    </>
  )
}

export default Toggle
