import React, { useState, useContext, useRef } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from '@chakra-ui/react'
import { DebtContext } from '../../context/DebtContext'
import { simplificaApi } from '../../services'
import { format, parseISO } from 'date-fns/esm'
import pad from '../../utils/pad'
import Logo from '../../assets/logo-v2.png'
import { sendAnalytics } from '../../utils/sendAnalytics'
import { AuthContext } from 'context/AuthContext'

interface EmailModalProps {
  isOpen: boolean
  onClose: () => void
}

const TIME_TO_RESEND = 90
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
function EmailModal({ isOpen, onClose }: EmailModalProps): JSX.Element {
  const [secondsToResend, setSecondsToResend] = useState(TIME_TO_RESEND)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const { debt, billet, statusCode, demoMode, paymentOption } =
    useContext(DebtContext)
  const { user } = useContext(AuthContext)
  const toast = useToast()
  const interval = useRef<any>(null)
  const [emailIsInvalid, setEmailIsInvalid] = useState(false)

  const handleSendEmail = async () => {
    if (!EMAIL_REGEX.test(email)) {
      setEmailIsInvalid(true)
      return
    }

    if (!debt || !user) {
      return
    }
    setIsLoading(true)

    try {
      const capitalizedClientName =
        debt?.client.at(0)?.toUpperCase() + debt?.client.slice(1)

      await simplificaApi.post('/share/email', {
        customer: debt.client,
        mailPayload: {
          sender: {
            name: `${capitalizedClientName} - simplifica.ai`,
            logo: `${process.env.REACT_APP_FRONTEND}${Logo}`,
          },
          destination: {
            name: user.name,
            email,
          },
          content: {
            template: 'boleto',
            templateValues: {
              digitableLine: !billet.pdf && billet.digitableLine,
            },
            subject: `Boleto acordo ${capitalizedClientName}`,
            dueDate: format(
              typeof debt?.dueDate === 'number'
                ? debt?.dueDate
                : parseISO(debt?.dueDate as string),
              'dd/MM/yyyy'
            ),
            value: `${billet.value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}${
              paymentOption?.installments && paymentOption?.installments > 1
                ? ` em ${paymentOption?.installments}x`
                : ''
            }`,
            attachments: billet.pdf ? [billet.pdf] : [],
          },
        },
      })
      if (secondsToResend < TIME_TO_RESEND) {
        setSecondsToResend(TIME_TO_RESEND)
      }
      onClose()
      toast({
        title: `Email enviado para ${email}`,
        description: 'Caso não encontre o email, verifique a caixa de spam',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      interval.current = setInterval(() => {
        if (secondsToResend > 0) {
          setSecondsToResend((state) => state - 1)
        } else {
          clearInterval(interval.current)
        }
      }, 1000)
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'EXPORTAR - EMAIL',
        status: 'OK',
        statusCode,
        isDemo: demoMode,
      })
    } catch (error) {
      toast({
        title: `Erro ao enviar email`,
        description: 'Ocorreu um erro inesperado',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'EXPORTAR - EMAIL',
        status: 'ERROR',
        statusCode,
        isDemo: demoMode,
      })
    }
    setIsLoading(false)
  }

  const isWaitingToResend =
    secondsToResend !== TIME_TO_RESEND && secondsToResend > 0

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent marginX={['1rem', '1rem']}>
        <ModalHeader color="primary.main" fontSize="lg">
          Email para receber boleto
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="1rem">
          <Input
            w="100%"
            variant="filled"
            placeholder="seuemail@domínio.com.br"
            borderRadius="10px"
            onChange={(e) => {
              setEmail(e.target.value)
              if (emailIsInvalid) setEmailIsInvalid(false)
            }}
            isInvalid={emailIsInvalid}
          />
          {emailIsInvalid && (
            <Text fontSize="xs" color="red">
              Email inválido
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="primary.main"
            _hover={{ bg: 'primary.hover' }}
            _active={{ bg: 'primary.hover' }}
            color="#fff"
            borderRadius="10px"
            isLoading={isLoading}
            onClick={handleSendEmail}
            disabled={isWaitingToResend || isLoading}
          >
            {isWaitingToResend ? (
              <>
                reenviar em{' '}
                {pad(String(parseInt(String(secondsToResend / 60))), 2, 'left')}
                :
                {pad(String(parseInt(String(secondsToResend % 60))), 2, 'left')}
              </>
            ) : (
              'enviar'
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EmailModal
