import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import { sendAnalytics } from '../utils/sendAnalytics'
import { AuthContext } from './AuthContext'
interface Contract {
  dueDate: string
  latePaymentDays: number
  contract: string
  product: string
  company: string
  currentValue: string
  totalValue: string
  discountValue: string
  titleNumber: string
}

interface Debt {
  totalValue: number
  discountValue: number
  discount: number
  qtyContracts: number
  walletInfo: {
    phone: string
    whatsapp: string
    email: string
  }
  data: unknown
  contracts: Contract[]
  client: string
  idAcao: string
  id: string
  dueDate: string | Date
}

interface DebtProviderProps {
  children: JSX.Element
}
interface Billet {
  digitableLine: string | null
  pdf: string | null
  value: number
}

interface User {
  name: string
  cpf: string
}

interface SelectOption {
  installments: number
  value: number
  otherInstallments: number
}

interface Installment {
  installments: number
  otherInstallments: number
  value: number
}

interface DebtContextProps {
  debt: Debt | null
  billet: Billet
  paymentOption: SelectOption | null
  statusCode: string
  demoMode: boolean
  handleEnd: (history: any, customer?: string) => void
  setDebt: (debt: Debt) => void
  setBilletExport: (digitableLine: string, pdf: string) => void
  setPaymentOption: (option: SelectOption) => void
  setStatusCode: Dispatch<SetStateAction<string>>
  setDemoMode: Dispatch<SetStateAction<boolean>>
}

export const DebtContext = createContext<DebtContextProps>({
  debt: null,
  statusCode: 'SAPI',
  demoMode: false,
  handleEnd: () => console.warn('no debt provider'),
  setDebt: () => console.warn('no debt provider'),
  setPaymentOption: () => console.warn('no debt provider'),
  setStatusCode: () => console.warn('no debt provider'),
  setBilletExport: () => console.warn('no debt provider'),
  setDemoMode: () => console.warn('no debt provider'),
  billet: {
    digitableLine: null,
    pdf: null,
    value: 0,
  },
  paymentOption: null,
})

export function DebtProvider({ children }: DebtProviderProps): JSX.Element {
  const [targetDebt, setTargetDebt] = useState<Debt | null>(null)
  const [paymentOption, setPaymentOption] = useState<SelectOption | null>(null)
  const [statusCode, setStatusCode] = useState<string>('SAPI')
  const [billet, setBillet] = useState({
    digitableLine: '',
    pdf: '',
    value: 0,
  })
  const [demoMode, setDemoMode] = useState(false)
  const { user } = useContext(AuthContext)

  function handleEnd(history: any, customer?: string) {
    setTargetDebt(null)
    setPaymentOption(null)
    setBillet({
      digitableLine: '',
      pdf: '',
      value: 0,
    })
    history.push(`${customer ? `/${customer}` : ''}/my-debts`)
    if (user) {
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'ACORDO FINALIZADO',
        status: 'ACORDO FINALIZADO',
        statusCode,
        isDemo: demoMode,
      })
    }
  }

  function setDebt(newValue: Debt) {
    setTargetDebt(newValue)
    setBillet((state) => ({ ...state, value: newValue.discountValue }))
  }

  function setBilletExport(digitableLine: string, pdf: string) {
    setBillet((state) => ({
      ...state,
      pdf,
      digitableLine,
    }))
  }

  function setPaymentOptionFunc(option: SelectOption) {
    setPaymentOption(option)
    setBillet((state) => ({
      ...state,
      value: +Number(option.value).toFixed(2),
    }))
  }

  return (
    <DebtContext.Provider
      value={{
        setDebt,
        setPaymentOption: setPaymentOptionFunc,
        debt: targetDebt,
        handleEnd,
        billet,
        paymentOption,
        setBilletExport,
        statusCode,
        setStatusCode,
        demoMode,
        setDemoMode,
      }}
    >
      {children}
    </DebtContext.Provider>
  )
}
