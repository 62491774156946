import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Flex,
  Image,
  Text,
  useMediaQuery,
  HStack,
  Icon,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  VStack,
  Tooltip,
} from '@chakra-ui/react'

import Logo from '../../assets/logo-v2-white.svg'
import LogoDemo from '../../assets/logo-demo.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { FiDollarSign, FiFileText, FiLogOut, FiMenu } from 'react-icons/fi'
import { DebtContext } from '../../context/DebtContext'
import { simplificaApi } from 'services'
import { AuthContext } from 'context/AuthContext'

const hasUserButtonRoutes = ['my-debts', 'debt', 'duplicate-billet']

interface INavbarProps {
  whiteText?: boolean
}

function Navbar({ whiteText }: INavbarProps): JSX.Element {
  const [hasDuplicateBillet, setHasDuplicateBillet] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isShorterThan600px] = useMediaQuery([
    '(max-width: 992px)',
    '(max-width: 600px)',
  ])
  const btnRef = useRef<HTMLButtonElement>(null)
  const { setDemoMode, demoMode } = useContext(DebtContext)
  const { user, handleLogout } = useContext(AuthContext)

  useEffect(() => {
    ;(async () => {
      if (location.pathname.match(/^\/my-debts/)) {
        const { data } = await simplificaApi.get('/debts/agreements')

        if (data.length > 0) {
          setHasDuplicateBillet(true)
        } else {
          setHasDuplicateBillet(false)
        }
      } else {
        setHasDuplicateBillet(false)
      }
    })()
  }, [location])

  const hasUserButton = hasUserButtonRoutes.some(
    (route) =>
      location &&
      location.pathname &&
      new RegExp(`^(/[A-z]*)*${`/${route}`}`).test(location.pathname)
  )

  function handleOpenDrawer() {
    setHasDuplicateBillet(false)
    onOpen()
  }

  function logout() {
    handleLogout()
    setDemoMode(false)
    history?.push('/search-debts')
  }

  return (
    <Flex
      h="80px"
      w="100%"
      justify={
        isShorterThan600px && !hasUserButton ? 'center' : 'space-between'
      }
      align="center"
      p="0 1rem"
      zIndex="var(--chakra-zIndices-overlay)"
      bg="transparent"
      transition="all .3s"
      backgroundColor="transparent"
      position="absolute"
      top="0"
      left="0"
    >
      <Image
        w={demoMode ? '210px' : '130px'}
        src={demoMode ? LogoDemo : Logo}
        alt="Logo"
        onClick={() => {
          history?.push('/')
        }}
        cursor="pointer"
      />
      {hasUserButton && !isShorterThan600px ? (
        <HStack spacing="2rem" mr="2rem">
          {location.pathname === '/duplicate-billet' && (
            <HStack
              cursor="pointer"
              onClick={() => history.push('/my-debts')}
              _hover={{ opacity: 0.8 }}
              transition="opacity 0.3s"
            >
              <Icon as={FiDollarSign} color="#fff" />
              <Text
                color={whiteText ? '#fff' : 'primary.main'}
                fontWeight="300"
              >
                minhas dívidas
              </Text>
            </HStack>
          )}
          {location.pathname === '/my-debts' && (
            <Tooltip
              label="Você tem acordos para emissão de segunda via!"
              isOpen={hasDuplicateBillet}
              backgroundColor="#5192f3"
              placement="bottom-end"
            >
              <HStack
                cursor="pointer"
                onClick={() => history.push('/duplicate-billet')}
                _hover={{ opacity: 0.8 }}
                transition="opacity 0.3s"
              >
                <Icon as={FiFileText} color="#fff" />
                <Text
                  color={whiteText ? '#fff' : 'primary.main'}
                  fontWeight="300"
                >
                  segunda via
                </Text>
              </HStack>
            </Tooltip>
          )}
          <HStack
            cursor="pointer"
            onClick={logout}
            _hover={{ opacity: 0.8 }}
            transit="opacity 0.3s"
          >
            <Icon as={FiLogOut} color="#fff" />
            <Text color={whiteText ? '#fff' : 'primary.main'} fontWeight="300">
              sair
            </Text>
          </HStack>
        </HStack>
      ) : (
        !isShorterThan600px && (
          <Text color={whiteText ? '#fff' : 'primary.main'} fontWeight="300">
            Uma forma simples de se livrar das dívidas
          </Text>
        )
      )}

      {hasUserButton && isShorterThan600px && (
        <>
          <Tooltip
            label="Você tem acordos para emissão de segunda via!"
            isOpen={hasDuplicateBillet}
            backgroundColor="#5192f3"
            placement="bottom-end"
          >
            <Button
              ref={btnRef}
              backgroundColor="transparent"
              onClick={handleOpenDrawer}
            >
              <Icon as={FiMenu} color="#fff" />
            </Button>
          </Tooltip>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Olá!</DrawerHeader>
              <DrawerBody>
                <VStack align="flex-start" spacing="1rem">
                  <HStack
                    cursor="pointer"
                    onClick={() => history.push('/duplicate-billet')}
                    _hover={{ opacity: 0.8 }}
                    transition="opacity 0.3s"
                    opacity={
                      location.pathname === '/duplicate-billet' ? 0.5 : 1
                    }
                  >
                    <Icon as={FiFileText} color="primary.main" />
                    <Text color="primary.main" fontWeight="300">
                      segunda via
                    </Text>
                  </HStack>
                  <HStack
                    cursor="pointer"
                    onClick={logout}
                    _hover={{ opacity: 0.8 }}
                    transit="opacity 0.3s"
                  >
                    <Icon as={FiLogOut} color="primary.main" />
                    <Text color="primary.main" fontWeight="300">
                      sair
                    </Text>
                  </HStack>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}

      {/* {!isShorterThan600px && (
          <Text color={whiteText ? '#fff' : 'primary.main'} fontWeight="300">
            Uma forma simples de se livrar das dívidas
          </Text>
        )}
        {hasUserButton && (
          <Menu placement="bottom-end">
            <MenuButton aria-label="Options" disabled={!getToken() && !user}>
              <BiUserCircle size={35} color="#fff" />
            </MenuButton>
            <MenuList border="none">
              <MenuItem icon={<FiUser />} isDisabled={true}>
                Perfil
              </MenuItem>
              <MenuItem
                icon={<FiFileText />}
                onClick={() => history.push('/duplicate-billet')}
              >
                Segunda via
              </MenuItem>

              <MenuItem
                icon={<FiLogOut />}
                onClick={() => {
                  removeToken()
                  setUser(null)
                  setDemoMode(false)
                  history?.push('/search-debts')
                }}
              >
                sair
              </MenuItem>
            </MenuList>
          </Menu>
      </HStack>
        )} */}
    </Flex>
  )
}

export default Navbar
