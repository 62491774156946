import React, { useEffect, useState, useContext } from 'react'
import {
  Box,
  Flex,
  HStack,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  Tooltip,
} from '@chakra-ui/react'
import DebtCard from './DebtCard'
import DebtCardSkeleton from './skeleton/DebtCardSkeleton'
import { simplificaApi } from '../../services/index'
import { RouteComponentProps } from 'react-router'
import { getToken } from '../../utils/authUtils'
import { mask } from '../../utils/remask'
import { DebtContext } from '../../context/DebtContext'
import { log } from '../../utils/log'
import ContractModal from '../../components/ContractModal'
import WalletInfoModal from 'components/WalletInfoModal'
import Navbar from 'components/Navbar'
import { AuthContext } from 'context/AuthContext'

interface Contract {
  dueDate: string
  latePaymentDays: number
  contract: string
  product: string
  company: string
  currentValue: string
  totalValue: string
  discountValue: string
  titleNumber: string
}

interface Debt {
  totalValue: number
  discountValue: number
  discount: number
  qtyContracts: number
  walletInfo: {
    phone: string
    whatsapp: string
    email: string
  }
  data: unknown
  contracts: Contract[]
  client: string
  idAcao: string
  id: string
  dueDate: string | Date
}

interface MyDebtsProps extends RouteComponentProps {
  customer?: string
}

function MyDebts({ history, customer }: MyDebtsProps): JSX.Element {
  const [isShorterThan1380px] = useMediaQuery('(max-width: 1380px)')

  const [loading, setLoading] = useState(false)
  const [debts, setDebts] = useState<Debt[]>([])
  const { setDemoMode } = useContext(DebtContext)
  const { user, isLoading } = useContext(AuthContext)
  const toast = useToast()

  const [isContractModalOpen, setIsContractModalOpen] = useState(false)
  const [isWalletInfoModalOpen, setIsWalletInfoModalOpen] = useState(false)
  const [selectedDebt, setSelectedDebt] = useState<Debt | null>(null)

  useEffect(() => {
    const token = getToken()

    if (!token) {
      history.replace('/search-debts')
      return
    }

    setLoading(true)
    ;(async () => {
      try {
        const token = getToken()

        if (!token) {
          history.push(
            `${customer ? `/${customer}` : ''}/search-debts?noAuth=true`
          )
          return
        }

        const { data } = await simplificaApi.get('/debts', {
          params: {
            customer: customer,
          },
        })

        setDemoMode(data.isDemo)
        log({ debts: data.debts })
        setDebts(data.debts)
      } catch (error) {
        toast({
          title: 'Ocorreu um erro',
          description: (error as any).message as string,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setLoading(false)
    })()
  }, [])

  function handleOpenContractModal(debt: Debt) {
    setIsContractModalOpen(true)
    setSelectedDebt(debt)
  }
  function handleOpenWalletInfoModal(debt: Debt) {
    setIsWalletInfoModalOpen(true)
    setSelectedDebt(debt)
  }

  return (
    <Flex
      w="100%"
      minH="100vh"
      alignItems="flex-start"
      justifyContent="center"
      position="relative"
      p="1rem"
      paddingTop="calc(1rem + 80px)"
    >
      <Navbar whiteText />
      <Box
        w="100%"
        h="100vh"
        position="fixed"
        top="0"
        left="0"
        overflow="hidden"
        zIndex="-1"
      >
        <Box
          h="100%"
          w="150%"
          position="absolute"
          top="-30%"
          left="-20%"
          background="primary.main"
          borderRadius="0 0 50px 0"
          transform="rotate(3deg)"
          zIndex="0"
        />
      </Box>
      <Flex
        w="100%"
        maxW={['100%', '100%', '90%', isShorterThan1380px ? '90%' : '70%']}
        flexDirection="column"
        zIndex="1"
      >
        {isLoading ? (
          <HStack>
            <Spinner color="#b8b8b8" size="sm" />
            <Text color="#b8b8b8" fontSize={['sm', 'md']}>
              Carregando...
            </Text>
          </HStack>
        ) : (
          <Tooltip
            label={user?.name}
            bg="primary.main"
            borderRadius="10px"
            placement="bottom-start"
          >
            <span>
              <Text color="#b8b8b8" fontSize={['sm', 'md']}>
                {user?.name &&
                  user?.name !== ' ' &&
                  (() => {
                    const userName = user?.name?.toUpperCase().split(' ')

                    return `${userName[0]} ${userName[1] ? userName[1] : ''}${
                      userName.length > 1 ? '...' : ''
                    }
                    - `
                  })()}
                CPF{' '}
                {user?.cpf &&
                  (() => {
                    if (user?.cpf && user?.cpf.length <= 11) {
                      return mask(user?.cpf as string, '999.999.999-99')
                    } else {
                      return mask(user?.cpf as string, '99.999.999/9999-99')
                    }
                  })()}
                {!user?.cpf && 'NÃO ENCONTRADO'}
              </Text>
            </span>
          </Tooltip>
        )}
        <Text
          as="h1"
          color="white"
          fontSize={['3xl', '3xl', '4xl', '4xl']}
          fontWeight="700"
          mb="4rem"
        >
          Minhas dívidas
        </Text>

        <Flex w="100%" flexWrap="wrap">
          {loading ? (
            <>
              <DebtCardSkeleton />
              <DebtCardSkeleton />
              <DebtCardSkeleton />
            </>
          ) : debts.length > 0 ? (
            debts.map((debt, idx) => (
              <DebtCard
                key={idx}
                debt={debt}
                animationDelay={idx * 0.3}
                onOpenContractModal={() => handleOpenContractModal(debt)}
                onOpenWalletInfoModal={() => handleOpenWalletInfoModal(debt)}
                customer={customer}
              />
            ))
          ) : user?.cpf ? (
            <Text color="#b8b8b8" fontSize={['md', 'lg']}>
              Nenhuma dívida encontrada em nossas bases de dados
            </Text>
          ) : (
            <Text color="#b8b8b8" fontSize={['md', 'lg']}>
              Seu CPF não se encontra em nossas bases de dados
            </Text>
          )}
        </Flex>
      </Flex>
      <ContractModal
        isOpen={isContractModalOpen}
        onClose={() => {
          setIsContractModalOpen(false)
          setSelectedDebt(null)
        }}
        debt={selectedDebt}
      />
      <WalletInfoModal
        isOpen={isWalletInfoModalOpen}
        onClose={() => {
          setIsWalletInfoModalOpen(false)
          setSelectedDebt(null)
        }}
        debt={selectedDebt}
      />
    </Flex>
  )
}

export default MyDebts
