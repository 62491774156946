import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { DebtContext } from 'context/DebtContext'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiMail, FiPhone, FiPhoneIncoming } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'
import { mask, unMask } from 'utils/remask'

import Logo from '../../assets/logo-v2.svg'
import { errorModalSchema } from './errorModal.schema'
import { simplificaApi } from 'services'

type ErrorModalProps = {
  isOpen: boolean
  toggle: () => void
}

export default function ErrorModal({
  isOpen,
  toggle,
}: ErrorModalProps): JSX.Element {
  const { debt } = useContext(DebtContext)
  const toast = useToast()
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(errorModalSchema),
    defaultValues: {
      phone: '',
    },
  })
  const [loading, setLoading] = useState(false)

  async function handleCallToUser(data: { phone: string }) {
    if (!debt) return
    try {
      setLoading(true)
      await simplificaApi.post('/share/phone/callback', {
        customer: debt.client,
        phone: unMask(data.phone),
      })
      toggle()
      toast({
        title: 'Ligaremos em breve!',
        description: 'Fique atento, logo você receberá uma ligação.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Ocorreu um erro',
        description: (error as any).message as string,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Image src={Logo} alt="logo" w="130px" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" alignItems="center">
            <Text
              as="h1"
              fontSize="2xl"
              fontWeight="bold"
              color="primary.main"
              textAlign="center"
            >
              Algo deu errado...
            </Text>
            <Text
              as="h3"
              fontSize="md"
              color="gray.500"
              textAlign="center"
              mb="2rem"
            >
              Infelizmente não conseguimos realizar seu acordo. Nos informe um
              número abaixo para que possamos te ligar e realizar o acordo.
            </Text>
            <Flex
              as="form"
              w="100%"
              direction="column"
              onSubmit={handleSubmit(handleCallToUser)}
            >
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiPhoneIncoming} color="gray.500" />
                    </InputLeftElement>
                    <Input
                      variant="filled"
                      placeholder="(99) 99999-9999"
                      marginBottom={
                        errors && !!errors.phone ? '0.25rem' : '1rem'
                      }
                      isInvalid={errors && !!errors.phone}
                      onChange={(e) =>
                        onChange(mask(e.target.value, '(99) 99999-9999'))
                      }
                      {...field}
                    />
                  </InputGroup>
                )}
              />
              {errors && errors.phone && (
                <Text fontSize="xs" color="red" mb="0.25rem">
                  {errors.phone.message}
                </Text>
              )}
              <Button
                bg="primary.main"
                _hover={{ bg: 'primary.hover' }}
                _active={{ bg: 'primary.hover' }}
                color="#fff"
                borderRadius="10px"
                type="submit"
                isLoading={loading}
              >
                receber ligação
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            w="100%"
          >
            <Text>Dúvidas fale com o nosso time!</Text>
            <HStack spacing="1rem">
              {debt?.walletInfo.phone && (
                <Link
                  href={`tel:${debt?.walletInfo.phone}`}
                  fontSize="1.5rem"
                  color="primary.main"
                >
                  <Icon as={FiPhone} />
                </Link>
              )}
              {debt?.walletInfo.whatsapp && (
                <Link
                  href={`https://wa.me/${debt?.walletInfo.whatsapp}`}
                  fontSize="1.5rem"
                  color="primary.main"
                >
                  <Icon as={FaWhatsapp} />
                </Link>
              )}
              {debt?.walletInfo.email && (
                <Link
                  href={`mailto:${debt?.walletInfo.email}`}
                  fontSize="1.5rem"
                  color="primary.main"
                >
                  <Icon as={FiMail} />
                </Link>
              )}
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
