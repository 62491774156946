import React, { useContext, useState } from 'react'
import {
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  FiCheck,
  FiChevronRight,
  FiCreditCard,
  FiDollarSign,
} from 'react-icons/fi'
import Credsystem from '../../assets/logo-credsystem.png'
import Bradesco from '../../assets/bradesco.svg'
import Boticario from '../../assets/o-boticario-logo.svg'
import Algar from '../../assets/algar-logo.svg'
import Eudora from '../../assets/eudora-logo.svg'
import Viacerta from '../../assets/viacerta-logo.svg'
import Natura from '../../assets/natura-logo.svg'
import { steps } from './steps'
import { DebtContext } from 'context/DebtContext'

interface WizardProps {
  customer?: string
}

function Wizard({ customer }: WizardProps): JSX.Element {
  const [step, setStep] = useState(0)
  const [isShorterThan620px] = useMediaQuery('(max-width: 620px)')
  const { debt } = useContext(DebtContext)

  function renderStep(): JSX.Element | null {
    const targetStep = steps[step]
    const nextStep = () => setStep(step + 1)
    const previousStep = () => setStep(step - 1)

    return targetStep ? (
      <targetStep.component
        onChangeStep={(step: number) => setStep(step)}
        nextStep={nextStep}
        previousStep={previousStep}
        customer={customer}
      />
    ) : null
  }

  function handleChangeStep(stepProp: number) {
    if (step === 2) return
    if (step !== stepProp && step > stepProp) {
      setStep(stepProp)
    }
  }

  return (
    <Flex w="100%" justifyContent="center" flexDirection="column" p="1rem 0">
      <Flex alignItems="center" mb="1.5rem" pl="1rem">
        <Flex alignItems="center">
          <Flex
            p="10px"
            bg={step === 0 ? 'white' : 'transparent'}
            borderRadius="10px"
            mr="0.75rem"
            transition="all .3s"
            onClick={() => handleChangeStep(0)}
            cursor={step < 2 ? 'pointer' : 'not-allowed'}
          >
            <Icon
              as={FiDollarSign}
              w={22}
              h={22}
              color={step === 0 ? 'primary.main' : 'white'}
              opacity={step > 0 ? '0.5' : '1'}
            />
          </Flex>
          {!isShorterThan620px && (
            <Text
              color="white"
              opacity={step > 0 ? '0.5' : '1'}
              cursor={step < 2 ? 'pointer' : 'not-allowed'}
            >
              Proposta
            </Text>
          )}
        </Flex>
        <FiChevronRight
          size={22}
          color="#fff"
          style={{ margin: isShorterThan620px ? '0.75rem' : '0 1.5rem' }}
        ></FiChevronRight>
        <Flex alignItems="center">
          <Flex
            p="10px"
            bg={step === 1 ? 'white' : 'transparent'}
            borderRadius="10px"
            mr="0.75rem"
            transition="all .3s"
          >
            <Icon
              as={FiCheck}
              w={22}
              h={22}
              color={step === 1 ? 'primary.main' : 'white'}
              opacity={step > 1 ? '0.5' : '1'}
            />
          </Flex>
          {!isShorterThan620px && (
            <Text color="white" opacity={step > 1 ? '0.5' : '1'}>
              Confirmação
            </Text>
          )}
        </Flex>
        <FiChevronRight
          size={22}
          color="#fff"
          style={{ margin: isShorterThan620px ? '0.75rem' : '0 1.5rem' }}
        ></FiChevronRight>
        <Flex alignItems="center">
          <Flex
            p="10px"
            bg={step === 2 ? 'white' : 'transparent'}
            borderRadius="10px"
            mr="0.75rem"
            transition="all .3s"
          >
            <Icon
              as={FiCreditCard}
              w={22}
              h={22}
              color={step === 2 ? 'primary.main' : 'white'}
            />
          </Flex>
          {!isShorterThan620px && <Text color="white">Pagamento</Text>}
        </Flex>
      </Flex>
      <Grid
        bg="white"
        borderRadius="10px"
        maxW="900px"
        boxShadow="0 0 20px 10px #00000010"
        minH="350px"
        templateColumns={isShorterThan620px ? '100%' : '40% 60%'}
      >
        {!isShorterThan620px && (
          <GridItem>
            <Flex height="100%" alignItems="center" justifyContent="center">
              {(debt?.client === 'credsystem' ||
                debt?.client === 'bradesco') && (
                <Image
                  src={debt?.client === 'credsystem' ? Credsystem : Bradesco}
                  w="150px"
                  objectFit="contain"
                  mr="1rem"
                  alt="logo dividendo"
                />
              )}
              {debt?.client === 'boticario' && (
                <Image
                  src={Boticario}
                  w="130px"
                  alt="logo-boticario"
                  objectFit="contain"
                  mr="1rem"
                />
              )}
              {debt?.client === 'natura' && (
                <Image
                  src={Natura}
                  w="90px"
                  alt="logo-natura"
                  objectFit="contain"
                  mr="1rem"
                />
              )}
              {debt?.client === 'eudora' && (
                <Image
                  src={Eudora}
                  w="90px"
                  alt="logo-eudora"
                  objectFit="contain"
                  mr="1rem"
                />
              )}
              {debt?.client === 'algar' && (
                <Image
                  src={Algar}
                  w="110px"
                  alt="logo-algar"
                  objectFit="contain"
                  mr="1rem"
                />
              )}
              {debt?.client === 'viacerta' && (
                <Image
                  src={Viacerta}
                  w="140px"
                  alt="logo-viacerta"
                  objectFit="contain"
                  mr="1rem"
                />
              )}
            </Flex>
          </GridItem>
        )}
        <GridItem>
          <Flex
            w="100%"
            flexDirection="column"
            p={['1.5rem', '2rem']}
            height="100%"
            justifyContent="space-between"
          >
            {renderStep()}
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default Wizard
