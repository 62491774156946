import React from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Image,
  useMediaQuery,
  Button,
} from '@chakra-ui/react'

import Logo from '../../assets/logo-v2-white.svg'
import { log } from '../../utils/log'

function UiErrorBoundary(): JSX.Element {
  const [isShorterThan992px, isShorterThan600px] = useMediaQuery([
    '(max-width: 992px)',
    '(max-width: 600px)',
  ])

  return (
    <Grid
      templateColumns="1fr"
      templateRows="100vh 0px"
      gap={0}
      onScroll={(e) => log(e.target)}
    >
      <Flex
        h="80px"
        w="100%"
        position="fixed"
        top="0"
        left="0"
        justify={isShorterThan600px ? 'center' : 'space-between'}
        align="center"
        p="0 2rem"
        zIndex="9999"
        bg="transparent"
        transition="all .3s"
      >
        <Image w="130px" src={Logo} alt="Logo" cursor="pointer" />
        <Text
          color={isShorterThan992px ? '#fff' : 'primary.main'}
          fontWeight="300"
        >
          Uma forma simples de se livrar das dívidas
        </Text>
      </Flex>
      <GridItem>
        <Flex
          h="100%"
          w="100%"
          position="relative"
          align="center"
          bg="#f5f5f5"
          overflow="hidden"
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <Box
            h="100%"
            w="150%"
            position="absolute"
            top={['-30%', '-30%', '-30%', '-30%']}
            left={['-20%', '-20%', '-20%', '-80%']}
            background={[
              'linear-gradient(0deg, #404040, #333 100%)',
              'linear-gradient(0deg, #404040, #333 100%)',
              'linear-gradient(0deg, #404040, #333 100%)',
              'linear-gradient(135deg, #404040, #333 100%)',
            ]}
            borderRadius="0 0 50px 0"
            transform={[
              'rotate(3deg)',
              'rotate(3deg)',
              'rotate(3deg)',
              'rotate(3deg)',
            ]}
            zIndex="0"
          />
          <Box
            w={['100%', '100%', '100%', '50%']}
            zIndex="1"
            p={['1rem', '1rem', '1rem', '0rem']}
          >
            <Box
              p={[
                '100px 1rem 1rem 1rem',
                '100px 1rem 1rem 1rem',
                '100px 1rem 1rem 1rem',
                '0px 1rem 0 4rem',
              ]}
            >
              <Text
                w="100%"
                fontSize={['4xl', '4xl', '4xl', '5xl']}
                color="white"
                fontWeight="700"
                textAlign={['center', 'center', 'center', 'left']}
                marginBottom={['1rem', '1rem', '1rem', '0']}
              >
                Um erro inesperado aconteceu :(
              </Text>
              <Text
                fontSize={['xl', 'xl', 'xl', '3xl']}
                textAlign={['center', 'center', 'center', 'left']}
                color="white"
                fontWeight="300"
                marginBottom="4rem"
              >
                Já estamos analisando o erro para resolvê-lo o mais rápido!
              </Text>
              <Text
                fontSize="md"
                textAlign={['center', 'center', 'center', 'left']}
                color="white"
                fontWeight="300"
              >
                Tente novamente clicando{' '}
                <Text
                  as="a"
                  href="#"
                  fontSize="md"
                  textAlign={['center', 'center', 'center', 'left']}
                  color="white"
                  fontWeight="300"
                  textDecoration="underline"
                  onClick={() => window.location.reload()}
                  _hover={{
                    opacity: '0.5',
                  }}
                >
                  aqui
                </Text>{' '}
              </Text>
            </Box>
          </Box>
        </Flex>
      </GridItem>
      {/* <GridItem bg="tomato"></GridItem> */}
    </Grid>
  )
}

export default UiErrorBoundary
