import { Box, Button, Flex, Input, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useQuery } from '../../hooks'
import { mask, unMask } from '../../utils/remask'
import { FiSearch } from 'react-icons/fi'
import { getToken, setToken } from '../../utils/authUtils'
import { simplificaApi } from 'services'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { searchDebts } from './searchDebts.schema'
import Navbar from 'components/Navbar'
import { AuthContext, LoginData } from 'context/AuthContext'

interface LoginProps extends RouteComponentProps {
  customer?: string
}

function Login({ location, history, customer }: LoginProps): JSX.Element {
  const [loading, setLoading] = useState(false)
  const { doc } = useQuery(location.search)
  const { handleLogin } = useContext(AuthContext)

  const {
    formState: { errors },
    getValues,
    setValue,
    setFocus,
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(searchDebts),
    reValidateMode: 'onChange',
    defaultValues: {
      cpfcnpj: (doc as string) || '',
      phone: '',
      email: '',
      captcha: '',
    },
  })

  useEffect(() => {
    if (doc) {
      setValue('cpfcnpj', mask(doc as string, '999.999.999-99'))
      setFocus('phone')
    }
  }, [doc])

  const handleSearchDebts = async (formData: Record<string, any>) => {
    const docValue = unMask(formData.cpfcnpj)

    setLoading(true)
    try {
      await handleLogin({
        captcha: formData.captcha,
        cpf: docValue,
        phone: formData.phone,
        email: formData.email,
      } as LoginData)

      setLoading(false)
      history.push(`${customer ? `/${customer}` : ''}/my-debts?doc=${docValue}`)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Flex
      w="100%"
      minH="100vh"
      alignItems="center"
      justifyContent="center"
      position="relative"
      overflow="hidden"
      p="1rem"
      paddingTop="calc(1rem + 80px)"
    >
      <Navbar whiteText />
      <Box
        h="100%"
        w="150%"
        position="absolute"
        top="-30%"
        left="-20%"
        background="primary.main"
        borderRadius="0 0 50px 0"
        transform="rotate(3deg)"
        zIndex="-1"
      />
      <Box
        p="2rem"
        borderRadius="10px"
        bg="white"
        zIndex="1"
        boxShadow="0 0 20px 10px #00000010"
        maxW="500px"
        w="100%"
        as="form"
        onSubmit={handleSubmit(handleSearchDebts)}
      >
        <Text
          as="h1"
          fontSize="xl"
          fontWeight="700"
          color="primary.main"
          w="100%"
          textAlign="center"
        >
          Consultar dívidas
        </Text>
        <Text
          as="h4"
          fontSize="md"
          marginBottom="2rem"
          textAlign="center"
          color="gray.500"
        >
          Consulte suas divídas gratuitamente
        </Text>
        <Text
          as="label"
          fontSize="sm"
          htmlFor="doc"
          color="primary.main"
          _after={{
            content: '"*"',
            color: '#888',
            marginLeft: '5px',
          }}
        >
          CPF
        </Text>

        <Controller
          name="cpfcnpj"
          control={control}
          render={({ field: { name, onBlur, onChange, ref, value } }) => (
            <Input
              variant="filled"
              placeholder="000.000.000-00"
              marginBottom={errors && !!errors.cpfcnpj ? '0.25rem' : '1rem'}
              name={name}
              onBlur={onBlur}
              value={value}
              maxLength={14}
              onChange={(e) => onChange(mask(e.target.value, '999.999.999-99'))}
              isInvalid={errors && !!errors.cpfcnpj}
              ref={ref}
              data-testid="search-debt-cpf"
            />
          )}
        />
        {errors && errors.cpfcnpj && (
          <Text fontSize="xs" color="red" mb="0.25rem">
            {errors.cpfcnpj.message}
          </Text>
        )}
        <Text
          as="label"
          fontSize="sm"
          htmlFor="phone"
          color="primary.main"
          _after={{
            content: '"*"',
            color: '#888',
            marginLeft: '5px',
          }}
        >
          Telefone
        </Text>
        <Controller
          name="phone"
          control={control}
          render={({ field: { name, onBlur, onChange, ref, value } }) => (
            <Input
              variant="filled"
              placeholder="(99) 99999-9999"
              marginBottom={errors && !!errors.phone ? '0.25rem' : '1rem'}
              name={name}
              onBlur={onBlur}
              value={mask(
                value,
                unMask(getValues('phone')).length <= 10
                  ? '(99) 9999-9999'
                  : '(99) 99999-9999'
              )}
              maxLength={15}
              onChange={(e) => onChange(unMask(e.target.value))}
              isInvalid={errors && !!errors.phone}
              ref={ref}
              data-testid="search-debt-phone"
            />
          )}
        />
        {errors && errors.phone && (
          <Text fontSize="xs" color="red" mb="0.25rem">
            {errors.phone.message}
          </Text>
        )}
        <Text as="label" fontSize="sm" htmlFor="email" color="primary.main">
          Email
        </Text>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              variant="filled"
              marginBottom={errors && !!errors.email ? '0.25rem' : '1rem'}
              placeholder="seuemail@dominio.com.br"
              isInvalid={errors && !!errors.email}
              {...field}
            />
          )}
        />
        {errors && errors.email && (
          <Text fontSize="xs" color="red" mb="0.25rem">
            {errors.email.message}
          </Text>
        )}
        <Box w="100%" marginBottom="0.25rem">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            onChange={(value) => {
              if (value) {
                setValue('captcha', value)
              }
            }}
            onExpired={() => setValue('captcha', '')}
            onErrored={() => setValue('captcha', '')}
          />
        </Box>
        {errors && errors.captcha && (
          <Text fontSize="xs" color="red">
            {errors.captcha.message}
          </Text>
        )}

        <Button
          w="100%"
          bg="primary.main"
          _hover={{ bg: 'primary.hover' }}
          _active={{ bg: 'primary.hover' }}
          color="#fff"
          borderRadius="10px"
          leftIcon={<FiSearch />}
          type="submit"
          isLoading={loading}
          marginTop="2rem"
        >
          consultar
        </Button>
      </Box>
    </Flex>
  )
}

export default Login
