import React, { useLayoutEffect, useState } from 'react'

export function useQuery(search: string): { [key: string]: unknown } {
  const [values, setValues] = useState<{ [key: string]: unknown }>({})

  useLayoutEffect(() => {
    const queryParams: string[] = search.replace(/[?]/g, '').split('&')
    const queryParamsObj: { [key: string]: unknown } = {}

    queryParams.forEach((query, idx) => {
      const [key, value] = query.split('=')

      queryParamsObj[key || `key${idx}`] = value ?? null
    })

    setValues(queryParamsObj)
  }, [search])

  return values
}
