const left = (str: string, numbers: number): string => {
  let padStr = str
  for (let i = 0; i <= numbers; i++) {
    padStr = '0' + padStr
  }

  return padStr
}

const right = (str: string, numbers: number): string => {
  let padStr = str
  for (let i = 0; i <= numbers; i++) {
    padStr += '0'
  }

  return padStr
}

const pad = (
  str: string,
  length: number,
  insertOn: 'right' | 'left' = 'right'
): string => {
  const strLength = str.length

  if (strLength >= length) {
    return str
  }

  const rest = length % strLength

  if (insertOn === 'left') {
    return left(str, rest)
  } else if (insertOn === 'right') {
    return right(str, rest)
  } else {
    throw new Error('insertOn value must be left or right.')
  }
}

export default pad
