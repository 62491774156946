import { useToast } from '@chakra-ui/react'
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'

import { simplificaApi } from 'services'
import { getToken, removeToken, setToken } from 'utils/authUtils'
import socket from 'socket.io-client'

export interface User {
  name: string
  phone: string
  cpf: string
  id: string
  actionId: string
}

export interface LoginData {
  name: string
  captcha: string
  cpf: string
  phone: string
  email: string
}

export const AuthContext = createContext<{
  user: User | null
  isLoggedIn: boolean
  isLoading: boolean
  handleLogin(payload: LoginData): Promise<void>
  handleLogout(): void
}>({
  user: null,
  isLoggedIn: false,
  isLoading: true,
  async handleLogin() {
    console.warn('No context provider')
  },
  handleLogout() {
    console.warn('No context provider')
  },
})

export function AuthProvider({
  children,
}: PropsWithChildren<any>): JSX.Element {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    ;(async () => {
      const token = getToken()

      if (!token) return

      setIsLoading(true)
      try {
        const { data } = await simplificaApi.get('/user')

        setUser(data)
      } catch (error) {
        toast({
          title: `Erro`,
          description:
            'Por motivos desconhecidos, não conseguimos os dados do usuário',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (!user) return
    console.log('user', user)

    const io = socket(process.env.REACT_APP_BACKEND_URL as string, {
      query: {
        id: user.id,
        actionId: user.actionId,
      },
    })

    io.on('user', (data) => {
      if (data.action === 'UPDATE_USERNAME') {
        setUser({
          ...user,
          name: data.payload.name,
        })
      }
    })

    return () => {
      io.disconnect()
    }
  }, [user])

  async function handleLogin({ name, captcha, cpf, phone, email }: LoginData) {
    const { data } = await simplificaApi.post('/auth', {
      captchaToken: captcha,
      cpfcnpj: cpf,
      name,
      phone,
      email,
      applicationId: '05e7a388-caf6-42c6-851e-6ea1f97a47c7',
    })

    setUser(data.user as User)
    setToken(data.token)
  }

  function handleLogout() {
    removeToken()
    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{
        handleLogout,
        handleLogin,
        isLoading,
        user,
        isLoggedIn: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
