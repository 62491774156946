import React from 'react'

import {
  BrowserRouter,
  Switch,
  Route,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom'

import Home from './pages/Home'
import SearchDebts from './pages/SearchDebts'
import MyDebts from './pages/MyDebts'
import Debt from './pages/Debt'
import Navbar from './components/Navbar'
import customers from 'config/customers'
import DuplicateBillet from 'pages/DuplicateBillet'

function RoutesWrapper({
  location,
  ...props
}: RouteComponentProps): JSX.Element {
  return (
    <>
      <Switch location={location}>
        <Route exact path="/" component={Home} />

        <Route exact path="/search-debts" component={SearchDebts} />
        <Route exact path="/my-debts" component={MyDebts} />
        <Route exact path="/debt" component={Debt} />
        <Route exact path="/duplicate-billet" component={DuplicateBillet} />

        {customers.map((customer, idx) => (
          <Route
            key={idx}
            exact
            path={`/${customer}`}
            render={(routeProps) => (
              <Home {...routeProps} customer={customer} />
            )}
          />
        ))}
        {customers.map((customer, idx) => (
          <Route
            key={idx}
            path={`/${customer}/search-debts`}
            render={(routeProps) => (
              <SearchDebts {...routeProps} customer={customer} />
            )}
          />
        ))}
        {customers.map((customer, idx) => (
          <Route
            key={idx}
            path={`/${customer}/my-debts`}
            render={(routeProps) => (
              <MyDebts {...routeProps} customer={customer} />
            )}
          />
        ))}
        {customers.map((customer, idx) => (
          <Route
            key={idx}
            path={`/${customer}/debt`}
            render={(routeProps) => (
              <Debt {...routeProps} customer={customer} />
            )}
          />
        ))}

        <Route path="*" render={() => <Redirect to="/" />} />
      </Switch>
    </>
  )
}

function Routes(): JSX.Element {
  return (
    <>
      <BrowserRouter>
        <Route path="*" component={RoutesWrapper} />
      </BrowserRouter>
    </>
  )
}

export default Routes
