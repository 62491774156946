import {
  Flex,
  IconButton,
  Text,
  useMediaQuery,
  Image,
  HStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  FiBattery,
  FiBell,
  FiCheck,
  FiChevronLeft,
  FiDollarSign,
  FiHome,
  FiMessageSquare,
  FiMoreHorizontal,
} from 'react-icons/fi'
import { format } from 'date-fns'
import Algar from '../../assets/algar-logo.svg'
import Eudora from '../../assets/eudora-logo.svg'
import oBoticario from '../../assets/o-boticario-logo.svg'
import Viacerta from '../../assets/viacerta-logo.svg'
import Natura from '../../assets/natura-logo.svg'
import Bradesco from '../../assets/bradesco.svg'
import Credsystem from '../../assets/credsystem.svg'
import simplifica from '../../assets/logo-v2.svg'
import bannerBg from '../../assets/phone-bg.jpg'
import bannerBg2 from '../../assets/banner-bg.jpg'
import agreement from '../../assets/agreement.svg'
import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

interface PhoneProps {
  customer?: string
}

function Phone({ customer }: PhoneProps): JSX.Element {
  const [dateNow, setDateNow] = useState<Date>(new Date())
  const [isShorterThan992px] = useMediaQuery('(max-width: 992px)')

  useEffect(() => {
    const interval = setInterval(() => setDateNow(new Date()), 60000)

    return () => clearInterval(interval)
  }, [])
  return (
    <Flex
      width="325px"
      maxW={['250px', '250px', '250px', '50%']}
      minW={['250px', '250px', '250px', '300px']}
      height="80%"
      maxH={['475px', '475px', '475px', '675px']}
      minH={['475px', '475px', '475px', '575px']}
      bg="#f5f5f5"
      boxShadow="0 0 20px 10px #00000010"
      borderRadius="50px"
      transform={[
        'rotate(3deg)',
        'rotate(3deg)',
        'rotate(3deg)',
        'rotate(-5deg)',
      ]}
      align="center"
      direction="column"
      p={['2rem 1rem', '2rem 1rem', '2rem 1rem', '3rem 2rem']}
    >
      <Flex
        w="100%"
        justify="space-between"
        align="center"
        p="0 1rem"
        marginBottom="1rem"
      >
        <Text fontSize="sm">{format(dateNow, 'HH:mm')}</Text>
        <FiBattery size={15} color="#333" />
      </Flex>
      <Flex w="100%" justify="space-between" align="center" mb="1.3rem">
        <IconButton
          colorScheme="#fff"
          aria-label="Go back"
          size={isShorterThan992px ? 'sm' : 'md'}
          icon={<FiChevronLeft color="#333" />}
          boxShadow="0 0 10px 5px #00000010"
          borderRadius="13px"
        />
        <Image src={simplifica} w="100px" />
        <IconButton
          colorScheme="#fff"
          aria-label="More options"
          size={isShorterThan992px ? 'sm' : 'md'}
          icon={<FiMoreHorizontal color="#333" />}
          boxShadow="0 0 10px 5px #00000010"
          borderRadius="13px"
        />
      </Flex>

      <Flex
        w="100%"
        h="100%"
        minH="calc(100% - 50px - 100px)"
        direction="column"
      >
        <Flex
          w="100%"
          h="100%"
          align="center"
          // justify="center"
          direction="column"
          bgImage={`url(${bannerBg2})`}
          bgPos="center"
          bgSize="cover"
          bgRepeat="no-repeat"
          borderRadius="20px"
          alignItems="center"
          justifyContent="center"
          position="relative"
          mb="1rem"
          overflow="hidden"
        >
          <Flex
            w="100%"
            h="100%"
            position="absolute"
            top="0px"
            left="0px"
            bg="#ffffffcc"
            borderRadius="20px"
            zIndex="0"
          />
          <HStack mb={customer ? '1rem' : '10px'} mt="1rem" zIndex="1">
            <Image src={agreement} w={['30px', '40px']} alt="mãos acordo" />
            <Text fontSize={['sm', 'md']} color="#333">
              parceiros
            </Text>
          </HStack>
          <Flex
            w="100%"
            justify="center"
            // bg="white"

            px="2rem"
            borderRadius="10px"
            zIndex="1"
          >
            {customer === 'natura' && (
              <Image
                src={Natura}
                w="100%"
                maxW="110px"
                objectFit="contain"
                alt="natura"
              />
            )}
            {customer === 'boticario' && (
              <Image
                src={oBoticario}
                w="100%"
                maxW="160px"
                objectFit="contain"
                alt="oboticario"
              />
            )}
            {customer === 'algar' && (
              <Image
                src={Algar}
                alt="algar"
                w="100%"
                maxW="150px"
                objectFit="contain"
              />
            )}
            {customer === 'eudora' && (
              <Image
                src={Eudora}
                alt="eudora"
                w="100%"
                maxW="110px"
                objectFit="contain"
              />
            )}
            {customer === 'viacerta' && (
              <Image
                src={Viacerta}
                alt="viacerta"
                w="100%"
                maxW="160px"
                objectFit="contain"
              />
            )}
            {!customer && (
              <Carousel
                plugins={[
                  'infinite',
                  {
                    resolve: autoplayPlugin,
                    options: {
                      interval: 4000,
                    },
                  },
                ]}
                animationSpeed={1000}
              >
                <Image
                  src={Natura}
                  w="100%"
                  maxW="110px"
                  objectFit="contain"
                  alt="natura"
                />
                <Image
                  src={oBoticario}
                  w="100%"
                  maxW="160px"
                  objectFit="contain"
                  alt="boticario"
                />
                <Image
                  src={Algar}
                  alt="algar"
                  w="100%"
                  maxW="130px"
                  objectFit="contain"
                />
                <Image
                  src={Eudora}
                  alt="eudora"
                  w="100%"
                  maxW="110px"
                  objectFit="contain"
                />
                <Image
                  src={Viacerta}
                  alt="viacerta"
                  w="100%"
                  maxW="160px"
                  objectFit="contain"
                />
                <Image
                  src={Bradesco}
                  alt="bradesco"
                  w="100%"
                  maxW="160px"
                  objectFit="contain"
                />
                <Image
                  src={Credsystem}
                  alt="credsystem"
                  w="100%"
                  maxW="160px"
                  objectFit="contain"
                />
              </Carousel>
            )}
          </Flex>
        </Flex>
        <Flex
          d={['none', 'none', 'none', 'flex']}
          w="100%"
          bgImage={`url(${bannerBg})`}
          h="100%"
          bgPos="center"
          bgSize="cover"
          bgRepeat="no-repeat"
          borderRadius="20px"
          position="relative"
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Flex
            w="100%"
            h="100%"
            position="absolute"
            top="0px"
            left="0px"
            bg="#333333cc"
            borderRadius="20px"
            zIndex="0"
          />
          <Text
            color="white"
            zIndex="1"
            fontSize={['md', 'lg']}
            textAlign="center"
          >
            Vem ser simples,
          </Text>
          <Text
            color="white"
            zIndex="1"
            fontSize={['xl', '2xl']}
            textAlign="center"
            fontWeight="bold"
          >
            Vem para a simplifica!
          </Text>
        </Flex>
        {/* <Flex
          w={['80px', '80px', '80px', '100px']}
          h={['80px', '80px', '80px', '100px']}
          border="1px solid #53d486"
          borderRadius="50%"
          align="center"
          justify="center"
          marginBottom="1rem"
        >
          <FiCheck size={30} color="#53d486" />
        </Flex>
        <Text color="#53d486" fontSize={['md', 'md', 'md', 'lg']}>
          Tudo pago!
        </Text> */}
      </Flex>
      <Flex
        w="100%"
        justify="space-between"
        minH="30px"
        align="center"
        p="1rem"
        pt="1.5rem"
        // marginBottom="1rem"
      >
        <FiHome size={isShorterThan992px ? 18 : 22} color="#333" />
        <FiMessageSquare size={isShorterThan992px ? 18 : 22} color="#9c9c9c" />
        <FiDollarSign size={isShorterThan992px ? 18 : 22} color="#9c9c9c" />
        <FiBell size={isShorterThan992px ? 18 : 22} color="#9c9c9c" />
      </Flex>
    </Flex>
  )
}

export default Phone
