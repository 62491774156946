import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  keyframes,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FiFileText, FiInfo, FiMoreHorizontal } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import Credsystem from '../../assets/logo-credsystem.png'
import Bradesco from '../../assets/bradesco.svg'
import Boticario from '../../assets/o-boticario-logo.svg'
import Algar from '../../assets/algar-logo.svg'
import Eudora from '../../assets/eudora-logo.svg'
import Viacerta from '../../assets/viacerta-logo.svg'
import Natura from '../../assets/natura-logo.svg'
import OffBg from '../../assets/off-bg.svg'
import { DebtContext } from '../../context/DebtContext'
import hasCustomerLogo from 'config/customers'

const fade = keyframes`
  from { opacity: 0; transform: translateY(-10px) }
  to { opacity: 1; transform: translateY(0px) }
`

interface Contract {
  dueDate: string
  latePaymentDays: number
  contract: string
  product: string
  company: string
  currentValue: string
  totalValue: string
  discountValue: string
  titleNumber: string
}

interface Debt {
  totalValue: number
  discountValue: number
  discount: number
  qtyContracts: number
  walletInfo: {
    phone: string
    whatsapp: string
    email: string
  }
  data: unknown
  contracts: Contract[]
  client: string
  idAcao: string
  id: string
  dueDate: string | Date
}
interface DebtCardProps {
  debt: Debt
  animationDelay: number
  onOpenContractModal: () => void
  onOpenWalletInfoModal: () => void
  customer?: string
}

function DebtCard({
  debt,
  animationDelay,
  onOpenContractModal,
  onOpenWalletInfoModal,
  customer,
}: DebtCardProps): JSX.Element {
  const history = useHistory()
  const { setDebt } = useContext(DebtContext)

  const goToDebt = () => {
    setDebt(debt)

    history.push(`/${customer ? `${customer}/` : ''}debt`)
  }

  if (!debt) return <></>

  const hasClientLogo = hasCustomerLogo.includes(debt.client)
  const discount = +((debt.discount * 100) / debt.totalValue).toFixed(0)

  return (
    <Flex
      w={[
        '100%',
        '100%',
        'calc(100% / 2 - 1.5rem)',
        'calc(100% / 2 - 1.5rem)',
        'calc(100% / 3 - 1.5rem)',
      ]}
      // minW="280px"
      bg="white"
      borderRadius="10px"
      p="2rem"
      mr={['0rem', '1.5rem']}
      mb="2rem"
      flexDirection="column"
      boxShadow=" 0 0 20px 10px #0000001a"
      opacity="0"
      animation={`${fade} 0.3s ${animationDelay}s ease-in forwards`}
    >
      <Flex
        width="100%"
        justifyContent={hasClientLogo ? 'space-between' : 'flex-end'}
      >
        {debt.client === 'credsystem' && (
          <Image
            src={Credsystem}
            w="150px"
            alt="logo-credsystem"
            objectFit="contain"
            mr="1rem"
          />
        )}
        {debt.client === 'bradesco' && (
          <Image
            src={Bradesco}
            w="130px"
            alt="logo-bradesco"
            objectFit="contain"
            mr="1rem"
          />
        )}
        {debt.client === 'boticario' && (
          <Image
            src={Boticario}
            w="130px"
            alt="logo-boticario"
            objectFit="contain"
            mr="1rem"
          />
        )}
        {debt.client === 'natura' && (
          <Image
            src={Natura}
            w="90px"
            alt="logo-natura"
            objectFit="contain"
            mr="1rem"
          />
        )}
        {debt.client === 'eudora' && (
          <Image
            src={Eudora}
            w="90px"
            alt="logo-eudora"
            objectFit="contain"
            mr="1rem"
          />
        )}
        {debt.client === 'algar' && (
          <Image
            src={Algar}
            w="110px"
            alt="logo-algar"
            objectFit="contain"
            mr="1rem"
          />
        )}
        {debt.client === 'viacerta' && (
          <Image
            src={Viacerta}
            w="140px"
            alt="logo-viacerta"
            objectFit="contain"
            mr="1rem"
          />
        )}

        {discount > 1 && (
          <Flex
            w="55px"
            minW="55px"
            height="55px"
            minH="55px"
            bgImg={`url(${OffBg})`}
            bgRepeat="no-repeat"
            bgPosition="center"
            bgSize="contain"
            alignItems="center"
            justifyContent="center"
            transform="rotate(16deg)"
          >
            <Text m="auto" color="white" fontSize="12px" fontWeight="bold">
              -{discount}%
            </Text>
          </Flex>
        )}
      </Flex>
      <Box py="2rem">
        <Text color="secondary" fontSize="12px">
          {debt.totalValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}{' '}
          em {debt.contracts.length} título{debt.contracts.length > 1 && 's'}
        </Text>
        <Flex alignItems="center">
          <Text color="secondary" fontSize="12px" mr="3px">
            por
          </Text>
          <Text
            as="h1"
            fontSize="4xl"
            color="primary.main"
            fontWeight="700"
            mr="3px"
            lineHeight="45px"
          >
            {debt.discountValue.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </Text>
          <Text color="secondary" fontSize="12px">
            à vista
          </Text>
        </Flex>
      </Box>
      <Flex w="100%">
        <Button
          w={['120px', '120px', '140px', '140px']}
          minW={['50px', '100px', '140px', '140px']}
          // height="50px"
          bg="primary.main"
          _hover={{ bg: 'primary.hover' }}
          _active={{ bg: 'primary.hover' }}
          color="#fff"
          borderRadius="10px"
          mr="1rem"
          onClick={goToDebt}
          data-testid="debt-button"
        >
          ver ofertas
        </Button>
        {/* <Button>
          <Icon
            as={FiMoreHorizontal}
            h="18"
            w="18"
            minH="18"
            minW="18"
            color="#333"
          />
        </Button> */}
        <Menu placement="bottom" strategy="fixed">
          <MenuButton
            p="5px"
            _hover={{ bg: 'primary.hover40' }}
            _active={{ bg: 'primary.hover20' }}
            borderRadius="50%"
            border="2px solid var(--chakra-colors-primary-main)"
            bg="white"
            aria-label="Options"
            minW="40px"
          >
            <Icon
              as={FiMoreHorizontal}
              h="18"
              w="18"
              minH="18"
              minW="18"
              color="primary.main"
            />
          </MenuButton>
          <MenuList border="none">
            <MenuItem icon={<FiFileText />} onClick={onOpenContractModal}>
              Ver títulos
            </MenuItem>
            <MenuItem icon={<FiInfo />} onClick={onOpenWalletInfoModal}>
              Mais informações
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  )
}

export default DebtCard
