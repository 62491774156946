import React, { useEffect, useState, useContext } from 'react'
import {
  Box,
  Flex,
  HStack,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
  Tooltip,
  Accordion,
  useDisclosure,
} from '@chakra-ui/react'
import { RouteComponentProps } from 'react-router'
import { mask } from '../../utils/remask'
import { DebtContext } from '../../context/DebtContext'
import Navbar from 'components/Navbar'
import { FiChevronLeft } from 'react-icons/fi'
import AgreementItem from './AgreementItem'
import ExportModal from './ExportModal'
import { UnionOrIntersectionType } from 'typescript'
import { simplificaApi } from 'services'
import AgreementItemSkeleton from './skeleton/AgreementItemSkeleton'
import { AuthContext } from 'context/AuthContext'

export interface AgreementInstallment {
  value: number
  id: number
  installments: number
  dueDate: string
}

export interface Agreement {
  client: string
  idCon: number
  idServ: number
  totalInstallments: number
  agreementDate: string
  code: number
  id: number
  agreementInstallments: AgreementInstallment[]
}

export type ExportAgreement = Agreement & {
  installmentId: number
  installmentValue: number
  installmentDueDate: string
}

export type Billet = {
  pdf: string
  digitableLine: string
}

export type ExportModalData = ExportAgreement & {
  billet: Billet
}

interface DuplicateBilletProps extends RouteComponentProps {
  customer?: string
}

function DuplicateBillet({ history }: DuplicateBilletProps): JSX.Element {
  const [isShorterThan1380px] = useMediaQuery('(max-width: 1380px)')

  const [loading, setLoading] = useState(false)
  const [agreements, setAgreements] = useState<Agreement[]>([])
  const { user, isLoading } = useContext(AuthContext)
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure()
  const [exportAgreement, setExportAgreement] =
    useState<ExportAgreement | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const { data } = await simplificaApi.get('/debts/agreements')

        setAgreements(data)
      } catch (error) {}
      setLoading(false)
    })()
  }, [])

  function handleOpenExportModal(agreement: Agreement, installmentId: number) {
    const targetInstallment = agreement.agreementInstallments.find(
      (installment) => installment.id === installmentId
    )

    if (!targetInstallment) return

    setExportAgreement({
      ...agreement,
      installmentId,
      installmentValue: targetInstallment.value,
      installmentDueDate: targetInstallment.dueDate,
    })
    onOpen()
  }

  function handleCloseExportModal() {
    setExportAgreement(null)
    onClose()
  }

  return (
    <Flex
      w="100%"
      minH="100vh"
      alignItems="flex-start"
      justifyContent="center"
      position="relative"
      p="1rem"
      paddingTop="calc(1rem + 80px)"
    >
      <Navbar whiteText />
      <Box
        w="100%"
        h="100vh"
        position="fixed"
        top="0"
        left="0"
        overflow="hidden"
        zIndex="-1"
      >
        <Box
          h="100%"
          w="150%"
          position="absolute"
          top="-30%"
          left="-20%"
          background="primary.main"
          borderRadius="0 0 50px 0"
          transform="rotate(3deg)"
          zIndex="0"
        />
      </Box>
      <Flex
        w="100%"
        maxW={['100%', '100%', '90%', isShorterThan1380px ? '90%' : '70%']}
        flexDirection="column"
        zIndex="1"
      >
        <HStack
          mb="1rem"
          onClick={() => {
            history.goBack()
          }}
          cursor="pointer"
        >
          <FiChevronLeft color="white" />
          <Text color="white" fontSize={['sm', 'md']}>
            voltar
          </Text>
        </HStack>
        {isLoading ? (
          <HStack>
            <Spinner color="#b8b8b8" size="sm" />
            <Text color="#b8b8b8" fontSize={['sm', 'md']}>
              Carregando...
            </Text>
          </HStack>
        ) : (
          <Tooltip
            label={user?.name}
            bg="primary.main"
            borderRadius="10px"
            placement="bottom-start"
          >
            <span>
              <Text color="#b8b8b8" fontSize={['sm', 'md']}>
                {user?.name &&
                  user?.name !== ' ' &&
                  (() => {
                    const userName = user?.name?.toUpperCase().split(' ')

                    return `${userName[0]} ${userName[1] ? userName[1] : ''}${
                      userName.length > 1 ? '...' : ''
                    }
                    - `
                  })()}
                CPF{' '}
                {user?.cpf &&
                  (() => {
                    if (user?.cpf && user?.cpf.length <= 11) {
                      return mask(user?.cpf as string, '999.999.999-99')
                    } else {
                      return mask(user?.cpf as string, '99.999.999/9999-99')
                    }
                  })()}
                {!user?.cpf && 'NÃO ENCONTRADO'}
              </Text>
            </span>
          </Tooltip>
        )}
        <Text
          as="h1"
          color="white"
          fontSize={['3xl', '3xl', '4xl', '4xl']}
          fontWeight="700"
          mb="4rem"
        >
          Segunda via
        </Text>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          maxW="1000px"
          borderRadius="10px"
          flexDir="column"
        >
          {loading ? (
            <>
              <AgreementItemSkeleton />
              <AgreementItemSkeleton />
              <AgreementItemSkeleton />
            </>
          ) : agreements.length > 0 ? (
            <Accordion w="100%" allowToggle>
              {agreements.map((agreement) => (
                <AgreementItem
                  key={agreement.id}
                  onOpenExportModal={handleOpenExportModal}
                  agreement={agreement}
                />
              ))}
            </Accordion>
          ) : (
            <Text color="#b8b8b8" fontSize={['md', 'lg']}>
              Nenhum acordo disponível para emissão de segunda via
            </Text>
          )}
        </Flex>
      </Flex>
      <ExportModal
        isOpen={isOpen}
        exportAgreement={exportAgreement}
        onCloseExportModal={handleCloseExportModal}
      />
    </Flex>
  )
}

export default DuplicateBillet
