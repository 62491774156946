import { Box, Button, HStack, Flex, SlideFade, Text } from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'

import React, { Fragment, useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import { StepComponentProps } from '.'
import PaymentOptionSelect from '../../../components/PaymentOptionSelect'
import { DebtContext } from '../../../context/DebtContext'
import { sendAnalytics } from '../../../utils/sendAnalytics'
import Toggle from '../../../components/ContractModal/Toggle'
import { simplificaApi } from 'services'
import OffBg from '../../../assets/off-bg.svg'
import { AuthContext } from 'context/AuthContext'

// import { Container } from './styles';

interface SelectOption {
  installments: number
  value: number
  otherInstallments: number
}

function Proposal({ nextStep, customer }: StepComponentProps): JSX.Element {
  const { debt, setPaymentOption, paymentOption, statusCode, demoMode } =
    useContext(DebtContext)
  const { user } = useContext(AuthContext)
  const [selectOpt, setSelectOpt] = useState<SelectOption | null>(null)
  const [options, setOptions] = useState<SelectOption[]>([])
  const [loadingOptions, setLoadingOptions] = useState(true)

  const history = useHistory()

  if (!debt) {
    history.push(`/${customer ? `${customer}/` : ''}my-debts`)
    return <Fragment />
  }

  const data = debt.data as any
  async function fetchOptions() {
    if (!debt || !user) return []

    try {
      const { data: opts } = await simplificaApi.get('/debts/payment-options', {
        headers: {
          debt: JSON.stringify({ client: debt.client, data: debt.data }),
        },
      })

      setOptions(opts)
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'PROPOSTA PAGAMENTO OPÇÕES',
        status: 'OK',
        statusCode,
        isDemo: demoMode,
      })
    } catch (error) {
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'PROPOSTA PAGAMENTO OPÇÕES',
        status: 'ERROR',
        statusCode,
        isDemo: demoMode,
      })
    }
    setLoadingOptions(false)
  }

  useEffect(() => {
    if (user) {
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'PROPOSTA STEP',
        status: 'OK',
        statusCode,
        isDemo: demoMode,
      })
    }

    setLoadingOptions(true)
    const delay = setTimeout(() => fetchOptions(), 500)

    return () => clearTimeout(delay)
  }, [])

  function handleNext() {
    if (
      selectOpt?.installments === 1 &&
      paymentOption?.installments === selectOpt?.installments
    )
      return nextStep()

    if (!selectOpt) {
      setPaymentOption(options[0])
    } else {
      setPaymentOption(selectOpt)
    }

    nextStep()
  }

  const discount = +((debt.discount * 100) / debt.totalValue).toFixed(0)

  return (
    <>
      <SlideFade in={true}>
        <Box height="100%">
          <Text as="h1" fontSize="2xl" fontWeight="bold" color="primary.main">
            Proposta {debt.client.toUpperCase()}
          </Text>
          <HStack spacing="5px">
            <Text color="secondary">
              {/* {debt.qtyContracts} contrato{debt.qtyContracts > 1 && 's'} */}
              {debt.contracts.length} título{debt.contracts.length > 1 && 's'}
            </Text>
            <Toggle debt={debt} color="secondary" />
          </HStack>
          <Text color="secondary" mb="2rem">
            Vencimento em{' '}
            {format(parseISO(debt.dueDate as string), 'dd/MM/yyyy')}
          </Text>

          <Box position="relative">
            <PaymentOptionSelect
              label={
                options.length > 1
                  ? 'escolher opção para pagamento'
                  : 'pagar em'
              }
              options={options}
              defaultValue={options[0]}
              onSelect={(opt) => setSelectOpt(opt)}
              value={selectOpt}
              placeholder={
                loadingOptions ? 'Carregando opções' : 'Selecione uma opção'
              }
              disabled={loadingOptions}
              isLoading={loadingOptions}
            />

            {discount > 0 && selectOpt?.installments === 1 && (
              <Flex
                w="40px"
                minW="40px"
                height="40px"
                minH="40px"
                bgImg={`url(${OffBg})`}
                bgRepeat="no-repeat"
                bgPosition="center"
                bgSize="contain"
                alignItems="center"
                justifyContent="center"
                transform="rotate(16deg)"
                position="absolute"
                top="5px"
                right="-20px"
              >
                <Text m="auto" color="white" fontSize="10px" fontWeight="bold">
                  -{discount}%
                </Text>
              </Flex>
            )}
          </Box>
        </Box>
      </SlideFade>
      <Flex w="100%" justifyContent="flex-end">
        <Button
          bg="primary.main"
          _hover={{ bg: 'primary.hover' }}
          _active={{ bg: 'primary.hover' }}
          color="#fff"
          borderRadius="10px"
          onClick={handleNext}
          disabled={loadingOptions}
        >
          próximo
        </Button>
      </Flex>
    </>
  )
}

export default Proposal
