import {
  Box,
  Button,
  Flex,
  Icon,
  SlideFade,
  Text,
  useToast,
} from '@chakra-ui/react'
import Toggle from 'components/ContractModal/Toggle'
import ErrorModal from 'components/ErrorModal'
import { AuthContext } from 'context/AuthContext'
import { format, parseISO } from 'date-fns'

import React, { useContext, useState, useEffect } from 'react'
import { FiCalendar, FiCreditCard, FiDollarSign } from 'react-icons/fi'
import { useHistory } from 'react-router'
import { simplificaApi } from 'services'
import { DebtContext } from '../../../context/DebtContext'
import { log } from '../../../utils/log'
import { sendAnalytics } from '../../../utils/sendAnalytics'

import { StepComponentProps } from './index'

function Confirmation({
  nextStep,
  previousStep,
  customer,
}: StepComponentProps): JSX.Element {
  const [loading, setLoading] = useState(false)
  const {
    debt,
    paymentOption,
    billet,
    setBilletExport,
    statusCode,
    setStatusCode,
    demoMode,
  } = useContext(DebtContext)
  const { user } = useContext(AuthContext)
  const toast = useToast()
  const data = debt?.data as any
  const history = useHistory()
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  if (!debt) {
    history.push('/my-debts')
    return <></>
  }

  useEffect(() => {
    if (user) {
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'CONFIRMAÇÃO STEP',
        status: 'OK',
        statusCode,
        isDemo: demoMode,
      })
    }
  }, [user])

  function toggleErrorModal() {
    setIsErrorModalOpen((state) => !state)
  }

  async function handleNext() {
    if (!debt || !user) return
    try {
      log(paymentOption)

      setLoading(true)
      const { data: response } = await simplificaApi.post(
        '/debts/issue-billet',
        {
          client: debt.client,
          data: {
            debt,
            paymentOption,
          },
        },
        {
          params: {
            customer,
          },
        }
      )
      if (response) {
        setBilletExport(response.digitableLine, response.pdf)
        setLoading(false)
        sendAnalytics({
          id: user.id,
          actionId: user.actionId,
          step: 'EMITIR BOLETO',
          status: 'OK',
          statusCode: paymentOption?.installments === 1 ? 'ACAV' : 'ACPA',
          isDemo: demoMode,
        })
        nextStep()
      } else {
        throw new Error('Erro ao gerar boleto')
      }
    } catch (error) {
      toast({
        title: 'Ocorreu um erro',
        description: (error as any).message as string,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      sendAnalytics({
        id: user.id,
        actionId: user.actionId,
        step: 'EMITIR BOLETO',
        status: 'ERROR',
        statusCode: paymentOption?.installments === 1 ? 'ACAV' : 'ACPA',
        isDemo: demoMode,
      })
      setStatusCode(paymentOption?.installments === 1 ? 'ACAV' : 'ACPA')
      setLoading(false)
      toggleErrorModal()
    }
  }

  return (
    <>
      <ErrorModal isOpen={isErrorModalOpen} toggle={toggleErrorModal} />
      <SlideFade in={true}>
        <Box height="100%">
          <Text
            as="h1"
            fontSize="2xl"
            fontWeight="bold"
            color="primary.main"
            mb="2rem"
          >
            Confirmação
          </Text>
          <Flex alignItems="center" mb="5px">
            <Icon as={FiDollarSign} color="secondary" mr="10px" />
            <Text color="secondary" mr="5px">
              {/* {debt.qtyContracts} contrato{debt.qtyContracts > 1 && 's'} */}
              {debt.contracts.length} título{debt.contracts.length > 1 && 's'}
            </Text>
            <Toggle debt={debt} color="secondary" />
          </Flex>
          <Flex alignItems="center" mb="5px">
            <Icon as={FiCalendar} color="secondary" mr="10px" />
            <Text color="secondary">
              Vencimento em{' '}
              {format(
                typeof debt?.dueDate === 'number'
                  ? debt?.dueDate
                  : parseISO(debt?.dueDate as string),
                'dd/MM/yyyy'
              )}
            </Text>
          </Flex>
          <Flex alignItems="center" mb="5px">
            <Icon as={FiCreditCard} color="secondary" mr="10px" />
            <Text color="secondary">
              {paymentOption &&
                (!paymentOption.otherInstallments
                  ? `${
                      paymentOption.installments
                    }x de ${paymentOption.value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}`
                  : `Entrada ${paymentOption.value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })} + ${
                      paymentOption.installments - 1
                    }x de ${paymentOption.otherInstallments.toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}`)}
            </Text>
          </Flex>
        </Box>
      </SlideFade>
      <Flex w="100%" justifyContent="flex-end">
        <Button
          bg="transparent"
          _hover={{ bg: 'primary.hover20' }}
          _active={{ bg: 'primary.hover40' }}
          color="primary.main"
          borderRadius="10px"
          onClick={previousStep}
          variant="ghost"
          mr="10px"
        >
          voltar
        </Button>
        <Button
          bg="primary.main"
          _hover={{ bg: 'primary.hover' }}
          _active={{ bg: 'primary.hover' }}
          color="#fff"
          borderRadius="10px"
          onClick={handleNext}
          isLoading={loading}
        >
          gerar boleto
        </Button>
      </Flex>
    </>
  )
}

export default Confirmation
