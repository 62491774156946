import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
interface TermsModalProps {
  isOpen: boolean
  onClose: () => void
}

function TermsModal({ isOpen, onClose }: TermsModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent marginX={['1rem', '1rem']}>
        <ModalHeader color="primary.main" fontSize="lg">
          Termos de uso
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="1rem">
          <Text>
            Declaro e garanto que toda informação sobre minha identidade,
            inclusive documentos como CPF e/ou CNPJ disponibilizada para acesso
            ao <strong>Simplifica.ai</strong> é verídica, a plataforma{' '}
            <strong>Simplifica.ai</strong> reserva-se o direito, de
            reestruturar, remover, negar, restringir, suspender, ou encerrar seu
            acesso a todos ou qualquer parte dos Serviços, determinar ainda a
            seu exclusivo critério, que você violou este site ou representa uma
            ameaça a plataforma, respondendo ainda por todos os seus atos diante
            da legislação vigente.
          </Text>
          <br />
          <Text>
            Os dados utilizados na plataforma foram fornecidos por integrações
            entre os parceiros, onde devido ao relacionamento comercial que você
            possui com eles os dados foram encontrados.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="primary.main"
            _hover={{ bg: 'primary.hover' }}
            _active={{ bg: 'primary.hover' }}
            color="#fff"
            borderRadius="10px"
            onClick={onClose}
          >
            fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TermsModal
