import { format } from 'date-fns'
import { simplificaApi } from '../services/index'

interface SendAnalyticsProps {
  id: string
  actionId: string
  statusCode: string | number
  status: string
  step: string
  phrase?: string
  response?: string
  isDemo?: boolean
}

export function sendAnalytics({
  id,
  actionId,
  step,
  phrase = '',
  response = '',
  status,
  statusCode,
  isDemo,
}: SendAnalyticsProps): void {
  if (!isDemo)
    simplificaApi.post('/activity/steps-marker', {
      id,
      actionId,
      session: `${id}_${actionId}${format(new Date(), 'yyyyMMdd_HHmmss')}`,
      step,
      phrase,
      response,
      status,
      statusCode,
    })
}
