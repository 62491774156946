import * as Yup from 'yup'

export const searchDebts = Yup.object().shape({
  cpfcnpj: Yup.string().min(14, 'CPF inválido').required('CPF é obrigatório'),
  phone: Yup.string()
    .min(10, 'Telefone inválido')
    .required('Telefone é obrigatório'),
  email: Yup.string().email('Email inválido'),
  captcha: Yup.string().required('Token é obrigatório'),
})
