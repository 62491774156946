import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import {
  FiCopy,
  FiDownload,
  FiMail,
  FiMessageSquare,
  FiShare2,
} from 'react-icons/fi'

import Logo from '../../assets/logo-v2.svg'
import { Billet, ExportModalData, ExportAgreement } from '.'
import { simplificaApi } from 'services'
import useShare from 'use-share'
import EmailModal from './EmailModal'
import SmsModal from './SmsModal'

type ExportModalProps = {
  isOpen: boolean
  onCloseExportModal: () => void
  exportAgreement: ExportAgreement | null
}

export default function ExportModal({
  isOpen,
  onCloseExportModal,
  exportAgreement,
}: ExportModalProps): JSX.Element {
  const [exportData, setExportData] = useState<Billet | null>(null)
  const [loading, setLoading] = useState(false)
  const [emailData, setEmailData] = useState<ExportModalData | null>(null)
  const [smsData, setSmsData] = useState<ExportModalData | null>(null)
  const { onCopy, hasCopied } = useClipboard(
    exportData?.digitableLine as string,
    20000
  )
  const { share } = useShare({
    title: `Meu boleto do simplifica.ai acordo ${exportAgreement?.client}`,
    text: `Linha Digitável: ${exportData?.digitableLine}${
      exportData?.pdf ? `\n\rLink para boleto: ${exportData?.pdf}\n\r` : '\n\r'
    }`,
    url: 'https://simplifica.ai',
  })

  function handleOpenEmailModal() {
    if (!exportData && exportAgreement) return

    setEmailData({
      ...(exportAgreement as ExportAgreement),
      billet: exportData as Billet,
    })
  }

  function handleCloseEmailModal() {
    setEmailData(null)
  }

  function handleOpenSmsModal() {
    if (!exportData && exportAgreement) return

    setSmsData({
      ...(exportAgreement as ExportAgreement),
      billet: exportData as Billet,
    })
  }

  function handleCloseSmsModal() {
    setSmsData(null)
  }

  async function handleExport() {
    try {
      if (exportAgreement) {
        setLoading(true)
        const { data } = await simplificaApi.post('/debts/agreements/billet', {
          data: {
            idBoleto: exportAgreement.installmentId.toString(),
            idCon: exportAgreement.idCon.toString(),
            idServ: exportAgreement.idServ.toString(),
          },
          client: exportAgreement.client,
        })

        setExportData(data)
      }
    } catch (error) {}
    setLoading(false)
  }

  async function downloadBillet() {
    if (!exportData) return

    const response = await fetch(exportData.pdf as string)
    const blob = await response.blob()

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `boleto.pdf`
    a.click()
  }

  useEffect(() => {
    if (isOpen && exportAgreement) {
      handleExport()
    }
  }, [isOpen, exportAgreement])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onCloseExportModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Image src={Logo} alt="logo" w="130px" />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Flex px="2rem" direction="column" alignItems="center">
                <Spinner size="xl" mb="2rem" />
                <Text
                  as="h1"
                  fontSize="2xl"
                  fontWeight="bold"
                  color="primary.main"
                  textAlign="center"
                >
                  Aguarde. Estamos gerando seu boleto!
                </Text>
              </Flex>
            ) : (
              <Flex px="2rem" direction="column" alignItems="center">
                <Tooltip label="Clique aqui" placement="right">
                  <Box
                    as="a"
                    href={exportData?.pdf}
                    target="_blank"
                    rel="nooponed noreferrer"
                    cursor="pointer"
                    mb="1rem"
                  >
                    {exportData && <QRCode value={exportData.pdf} size={100} />}
                  </Box>
                </Tooltip>
                <Text
                  as="h1"
                  fontSize="2xl"
                  fontWeight="bold"
                  color="primary.main"
                  textAlign="center"
                >
                  Seu boleto está pronto!
                </Text>
                <Text as="h3" fontSize="md" color="gray.500" textAlign="center">
                  Utilize os métodos abaixo para pagar seu boleto.
                </Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            {!loading && (
              <HStack
                w="100%"
                justify="center"
                spacing="1.5rem"
                p="2rem"
                cursor="pointer"
              >
                <Tooltip
                  label={
                    hasCopied
                      ? 'Linha digitável copiada'
                      : 'Copiar linha digitável'
                  }
                >
                  <span>
                    <Icon
                      as={FiCopy}
                      onClick={onCopy}
                      fontSize="1.665rem"
                      cursor="pointer"
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Baixar boleto">
                  <span>
                    <Icon
                      as={FiDownload}
                      onClick={downloadBillet}
                      fontSize="1.665rem"
                      cursor="pointer"
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Receber por email">
                  <span>
                    <Icon
                      as={FiMail}
                      fontSize="1.665rem"
                      cursor="pointer"
                      onClick={handleOpenEmailModal}
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Receber por SMS">
                  <span>
                    <Icon
                      onClick={handleOpenSmsModal}
                      as={FiMessageSquare}
                      fontSize="1.665rem"
                      cursor="pointer"
                    />
                  </span>
                </Tooltip>
                <Tooltip label="Compartilhar">
                  <span>
                    <Icon
                      as={FiShare2}
                      onClick={share}
                      fontSize="1.665rem"
                      cursor="pointer"
                    />
                  </span>
                </Tooltip>
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <EmailModal
        isOpen={!!emailData}
        onClose={handleCloseEmailModal}
        data={emailData}
      />
      <SmsModal
        isOpen={!!smsData}
        onClose={handleCloseSmsModal}
        data={smsData}
      />
    </>
  )
}
