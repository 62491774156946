import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Text,
  useMediaQuery,
  Image,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { mask, unMask } from '../../utils/remask'

import { FiCheck, FiDollarSign, FiList, FiSearch } from 'react-icons/fi'
import { RouteComponentProps } from 'react-router-dom'
import Algar from '../../assets/algar-logo.svg'
import Eudora from '../../assets/eudora-logo.svg'
import oBoticario from '../../assets/o-boticario-logo.svg'
import Viacerta from '../../assets/viacerta-logo.svg'
import Bradesco from '../../assets/bradesco.svg'
import Credsystem from '../../assets/credsystem.svg'
import Natura from '../../assets/natura-logo.svg'
import Phone from './Phone'
import Terms from './Terms'

import Logo from '../../assets/logo-v2-white.svg'
import Navbar from 'components/Navbar'

interface HomeProps extends RouteComponentProps {
  customer?: string
}

function Home({ history, customer }: HomeProps): JSX.Element {
  const [isShorterThan480px, isShorterThan642px] = useMediaQuery([
    '(max-width: 480px)',
    '(max-width: 642px)',
  ])
  const [doc, setDoc] = useState('')
  const [docIsInvalid, setDocIsInvalid] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleGoToSearch = () => {
    history.push(
      `${customer ? `/${customer}` : ''}/search-debts${
        doc ? `?doc=${unMask(doc)}` : ''
      }`
    )
  }

  const handleDocChange = (e: any) => {
    if (docIsInvalid) setDocIsInvalid(false)

    setDoc(mask(e.target.value, '999.999.999-99'))
  }

  return (
    <>
      <Grid
        templateColumns="1fr"
        templateRows={[
          'auto auto auto auto',
          'auto auto auto auto',
          'auto auto auto auto',
          '100vh auto auto auto',
        ]}
        gap={0}
      >
        <GridItem>
          <Flex
            h="100%"
            w="100%"
            position="relative"
            align="center"
            bg="#f5f5f5"
            // backgroundSize="cover"
            // backgroundRepeat="no-repeat"
            // backgroundImage={`url(${Background})`}
            // backgroundPosition="center"
            zIndex="0"
            overflow="hidden"
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <Navbar />
            <Box
              h="100%"
              w="150%"
              position="absolute"
              top={['-30%', '-30%', '-30%', '-10%']}
              left={['-20%', '-20%', '-20%', '-80%']}
              background="primary.main"
              borderRadius="0 0 50px 0"
              transform="rotate(3deg)"
              zIndex="-1"
            />

            <Box
              w={['100%', '100%', '100%', '50%']}
              p={['1rem', '1rem', '1rem', '0rem']}
            >
              <Box
                p={[
                  '100px 1rem 1rem 1rem',
                  '100px 1rem 1rem 1rem',
                  '100px 1rem 1rem 1rem',
                  '0px 1rem 0 4rem',
                ]}
              >
                <Text
                  w="100%"
                  fontSize={['4xl', '4xl', '4xl', '5xl']}
                  color="white"
                  fontWeight="700"
                  textAlign={['center', 'center', 'center', 'left']}
                  marginBottom={['1rem', '1rem', '1rem', '0']}
                >
                  Negocie suas dívidas
                  {!isShorterThan642px && ' com descontos incríveis!'}
                </Text>
                {customer === 'credsystem' ? (
                  <Text
                    fontSize={['xl', 'xl', 'xl', '3xl']}
                    textAlign={['center', 'center', 'center', 'left']}
                    color="white"
                    fontWeight="300"
                    marginBottom="4rem"
                  >
                    É seguro, simples e ainda a{' '}
                    <strong
                      style={{
                        background:
                          'linear-gradient(to right, #0ae5fe, #3477ff, #512fff, #bc00c2, #f90099)',
                        padding: '0 2px',
                      }}
                    >
                      credsystem
                    </strong>{' '}
                    oferece um <strong>descontão</strong>. Aproveite! .
                  </Text>
                ) : customer === 'bradesco' ? (
                  <Text
                    fontSize={['xl', 'xl', 'xl', '3xl']}
                    textAlign={['center', 'center', 'center', 'left']}
                    color="white"
                    fontWeight="300"
                    marginBottom="4rem"
                  >
                    É seguro, simples e ainda o{' '}
                    <strong
                      style={{
                        background:
                          'linear-gradient(270deg, #F56077 0%, #C20757 52%, #B41A83 100%)',
                        padding: '0 2px',
                      }}
                    >
                      bradesco
                    </strong>{' '}
                    oferece um <strong>descontão</strong>. Aproveite! .
                  </Text>
                ) : (
                  <Text
                    fontSize={['xl', 'xl', 'xl', '3xl']}
                    textAlign={['center', 'center', 'center', 'left']}
                    color="white"
                    fontWeight="300"
                    marginBottom="4rem"
                  >
                    É simples, rápido e de quebra ainda damos um descontão!
                  </Text>
                )}

                <Flex
                  as="form"
                  bg="white"
                  p="1rem"
                  maxW={['500px', '500px', '500px', '600px']}
                  borderRadius="10px"
                  alignItems="center"
                  margin={['0 auto', '0 auto', '0 auto', '0']}
                  onSubmit={handleGoToSearch}
                >
                  <Icon
                    as={FiSearch}
                    h="18"
                    w="18"
                    minH="18"
                    minW="18"
                    color="primary.main"
                    display={['none', 'inline', 'inline', 'inline']}
                  />
                  <Input
                    variant="filled"
                    placeholder="Seu CPF"
                    marginRight="1rem"
                    marginLeft={['0rem', '1rem', '1rem', '1rem']}
                    borderRadius="10px"
                    onChange={handleDocChange}
                    value={doc}
                  />
                  <Button
                    w={['50px', '100px', '140px', '140px']}
                    minW={['50px', '100px', '140px', '140px']}
                    // height="50px"
                    bg="primary.main"
                    _hover={{ bg: 'primary.hover' }}
                    _active={{ bg: 'primary.hover' }}
                    color="#fff"
                    borderRadius="10px"
                    type="submit"
                    aria-label="pesquisar"
                  >
                    {isShorterThan480px ? (
                      <Icon
                        as={FiSearch}
                        h="18"
                        w="18"
                        minH="18"
                        minW="18"
                        color="#fff"
                      />
                    ) : (
                      'consultar'
                    )}
                  </Button>
                </Flex>
              </Box>
            </Box>
            <Flex
              w={['100%', '100%', '100%', '50%']}
              h={['auto', 'auto', 'auto', '100%']}
              zIndex="1"
              align="center"
              justify="center"
              p="1rem"
            >
              <Phone customer={customer} />
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex
            align="center"
            p={['4rem 1rem', '4rem 1rem', '4rem 1rem', '8rem 1rem']}
            direction="column"
            bg="#f5f5f5"
          >
            <Text
              fontSize={['xl', 'xl', 'xl', '3xl']}
              mb="2rem"
              textAlign="center"
              fontWeight="bold"
              color="primary.main"
            >
              Negocie suas dívidas com as empresas:
            </Text>
            <Grid
              as="section"
              w={['100%', '100%', '100%', '100%', '70%']}
              templateColumns={[
                '1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr 1fr 1fr',
              ]}
              templateRows={[
                'repeat(1fr, 2)',
                'repeat(1fr, 2)',
                'repeat(1fr, 2)',
                'repeat(1fr, 2)',
                '1fr',
              ]}
              gap="2rem 0"
              p={['0rem', '2rem']}
              alignItems="center"
              justifyContent="center"
            >
              <GridItem
                p="0.5rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  as="a"
                  href="https://www.natura.com.br/"
                  target="_blank"
                  rel="noopened noreferrer"
                  aria-label="natura"
                  justifyContent="center"
                >
                  <Image
                    src={Natura}
                    w="130px"
                    maxW="130px"
                    alt="logo-natura"
                  />
                </Box>
              </GridItem>
              <GridItem
                p="0.5rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  as="a"
                  href="https://www.boticario.com.br/"
                  target="_blank"
                  rel="noopened noreferrer"
                  aria-label="boticario"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={oBoticario}
                    w="190px"
                    maxW="190px"
                    alt="logo-boticario"
                  />
                </Box>
              </GridItem>
              <GridItem
                p="0.5rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  as="a"
                  href="https://algartelecom.com.br/"
                  target="_blank"
                  rel="noopened noreferrer"
                  aria-label="algar"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image src={Algar} w="150px" maxW="150px" alt="logo-algar" />
                </Box>
              </GridItem>
              <GridItem
                p="0.5rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  as="a"
                  href="https://www.eudora.com.br/"
                  target="_blank"
                  rel="noopened noreferrer"
                  aria-label="eudora"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={Eudora}
                    w="150px"
                    maxW="150px"
                    alt="logo-eudora"
                  />
                </Box>
              </GridItem>
              <GridItem
                p="0.5rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  as="a"
                  href="https://viacertabanking.com.br/"
                  target="_blank"
                  rel="noopened noreferrer"
                  aria-label="viacerta"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={Viacerta}
                    w="180px"
                    maxW="180px"
                    alt="logo-viacerta"
                    margin="1rem 0"
                  />
                </Box>
              </GridItem>
              <GridItem
                p="0.5rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  as="a"
                  href="https://banco.bradesco/"
                  target="_blank"
                  rel="noopened noreferrer"
                  aria-label="bradesco"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={Bradesco}
                    alt="bradesco"
                    w="100%"
                    maxW="180px"
                    objectFit="contain"
                  />
                </Box>
              </GridItem>
              <GridItem
                p="0.5rem"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  as="a"
                  href="https://www.credsystem.com.br/"
                  target="_blank"
                  rel="noopened noreferrer"
                  aria-label="credsystem"
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    src={Credsystem}
                    alt="credsystem"
                    w="100%"
                    maxW="180px"
                    objectFit="contain"
                  />
                </Box>
              </GridItem>
            </Grid>
            {/* <Button
            minW={['50px', '100px', '140px', '140px']}
            // height="50px"
            bg="primary.main"
            _hover={{ bg: 'primary.hover' }}
            _active={{ bg: 'primary.hover' }}
            color="#fff"
            borderRadius="10px"
          >
            ver mais parceiros
          </Button> */}
          </Flex>
        </GridItem>
        <GridItem>
          <Flex
            align="center"
            p="5rem 1rem"
            direction="column"
            bg="primary.main"
          >
            <Text
              fontSize={['xl', 'xl', 'xl', '3xl']}
              textAlign="center"
              fontWeight="bold"
              color="white"
            >
              Resolva tudo de forma simples!
            </Text>
            <Text
              fontSize={['xl', 'xl', 'xl', 'xl']}
              mb="2rem"
              textAlign="center"
              color="subtitle"
            >
              Livre-se das dívidas em quatro passos
            </Text>
            <Grid
              templateColumns={[
                '1fr',
                '1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr 1fr 1fr',
              ]}
              templateRows={[
                'repeat(1fr, 4)',
                'repeat(1fr, 4)',
                'repeat(1fr, 2)',
                'repeat(1fr, 2)',
                '1fr',
              ]}
              py="2rem"
              align="center"
              gap="2rem"
            >
              <GridItem>
                <Flex direction="column" align="center" w="100%" maxW="300px">
                  <Flex
                    mb="2rem"
                    border="3px solid #fff"
                    borderRadius="50%"
                    align="center"
                    justify="center"
                    w="100px"
                    h="100px"
                  >
                    <Icon
                      as={FiSearch}
                      h="45"
                      w="45"
                      minH="45"
                      minW="45"
                      color="#fff"
                    />
                  </Flex>
                  <Text
                    fontSize="lg"
                    textAlign="center"
                    fontWeight="bold"
                    color="white"
                    mb="0.5rem"
                  >
                    Acesse
                  </Text>
                  <Text fontSize="md" textAlign="center" color="white">
                    Localize as dívidas em nossos parceiros
                  </Text>
                </Flex>
              </GridItem>

              <GridItem>
                <Flex direction="column" align="center" w="100%" maxW="300px">
                  <Flex
                    mb="2rem"
                    border="3px solid #fff"
                    borderRadius="50%"
                    align="center"
                    justify="center"
                    w="100px"
                    h="100px"
                  >
                    <Icon
                      as={FiList}
                      h="45"
                      w="45"
                      minH="45"
                      minW="45"
                      color="#fff"
                    />
                  </Flex>
                  <Text
                    fontSize="lg"
                    textAlign="center"
                    fontWeight="bold"
                    color="white"
                    mb="0.5rem"
                  >
                    Veja as opções
                  </Text>
                  <Text fontSize="md" textAlign="center" color="white">
                    Sempre os melhores descontos e parcelamentos
                  </Text>
                </Flex>
              </GridItem>

              <GridItem>
                <Flex direction="column" align="center" w="100%" maxW="300px">
                  <Flex
                    mb="2rem"
                    border="3px solid #fff"
                    borderRadius="50%"
                    align="center"
                    justify="center"
                    w="100px"
                    h="100px"
                  >
                    <Icon
                      as={FiDollarSign}
                      h="45"
                      w="45"
                      minH="45"
                      minW="45"
                      color="#fff"
                    />
                  </Flex>
                  <Text
                    fontSize="lg"
                    textAlign="center"
                    fontWeight="bold"
                    color="white"
                    mb="0.5rem"
                  >
                    Escolha
                  </Text>
                  <Text fontSize="md" textAlign="center" color="white">
                    Selecione a condição que cabe no seu bolso e confirme seu
                    acordo
                  </Text>
                </Flex>
              </GridItem>

              <GridItem>
                <Flex direction="column" align="center" w="100%" maxW="300px">
                  <Flex
                    mb="2rem"
                    border="3px solid #fff"
                    borderRadius="50%"
                    align="center"
                    justify="center"
                    w="100px"
                    h="100px"
                  >
                    <Icon
                      as={FiCheck}
                      h="45"
                      w="45"
                      minH="45"
                      minW="45"
                      color="#fff"
                    />
                  </Flex>
                  <Text
                    fontSize="lg"
                    textAlign="center"
                    fontWeight="bold"
                    color="white"
                    mb="0.5rem"
                  >
                    Pronto!
                  </Text>
                  <Text fontSize="md" textAlign="center" color="white">
                    Pague diretamente para o credor e deixe a dívida pra trás
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </Flex>
        </GridItem>
        {/* <GridItem>
          <Flex
            w="100%"
            bg="#f5f5f5"
            p={['2rem', '2rem', '5rem', '5rem']}
            justify="center"
          >
            <Grid
              templateColumns={[
                '1fr',
                '1fr',
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr 1fr 1fr',
              ]}
              templateRows={[
                'repeat(1fr, 4)',
                'repeat(1fr, 4)',
                'repeat(1fr, 2)',
                'repeat(1fr, 2)',
                '1fr',
              ]}
              py="2rem"
              gap="2rem"
            >
              <GridItem>
                <Flex direction="column" mr="3rem">
                  <Text fontSize="xl" fontWeight="bold" color="primary.main">
                    Seja nosso parceiro!
                  </Text>
                  <Text fontSize="lg" color="secondary" mb="1rem">
                    Vem ser simples com a simplifica
                  </Text>
                  <Stack isInline align="center">
                    <FiMail size={18} color="#333" />
                    <Text
                      as="a"
                      href="mailto:comercial@simplifica.ai"
                      textDecor="underline"
                    >
                      comercial@simplifica.ai
                    </Text>
                  </Stack>
                </Flex>
              </GridItem>

              <GridItem>
                <Flex direction="column" mr="3rem">
                  <Text fontSize="xl" fontWeight="bold" color="primary.main">
                    Fale conosco!
                  </Text>
                  <Text fontSize="lg" color="secondary" mb="1rem">
                    Tire dúvidas ou dê sugestões pra gente
                  </Text>
                  <Stack isInline align="center" mb="0.5rem">
                    <FiMail size={18} color="#333" />
                    <Text
                      as="a"
                      href="mailto:contato@simplifica.ai"
                      textDecor="underline"
                    >
                      contato@simplifica.ai
                    </Text>
                  </Stack>
                  <Stack isInline align="center" mb="0.5rem">
                    <FaWhatsapp size={18} color="#333" />
                    <Text
                      as="a"
                      href="https://wa.me/5511912311339"
                      textDecor="underline"
                    >
                      (11) 91234-1239
                    </Text>
                  </Stack>
                  <Stack isInline align="center">
                    <FiPhone size={18} color="#333" />
                    <Text as="a" href="tel:5511912311339" textDecor="underline">
                      (11) 91234-1239
                    </Text>
                  </Stack>
                </Flex>
              </GridItem>

              <GridItem>
                <Flex direction="column">
                  <Text fontSize="xl" fontWeight="bold" color="primary.main">
                    Siga a gente nas redes sociais!
                  </Text>
                  <Text fontSize="lg" color="secondary" mb="1rem">
                    Fique por dentro das novidades do simplifica e muito mais
                  </Text>
                  <Stack isInline align="center">
                    <Box
                      as="a"
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopened noreferrer"
                    >
                      <FaInstagramSquare size={35} color="#333" />
                    </Box>
                    <Box
                      as="a"
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopened noreferrer"
                    >
                      <FaTwitterSquare size={35} color="#333" />
                    </Box>
                    <Box
                      as="a"
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopened noreferrer"
                    >
                      <FaFacebookSquare size={35} color="#333" />
                    </Box>
                  </Stack>
                </Flex>
              </GridItem>

              <GridItem>
                <Flex direction="column">
                  <Text fontSize="xl" fontWeight="bold" color="primary.main">
                    Termos de uso
                  </Text>
                  <Text cursor="pointer" textDecor="underline" onClick={onOpen}>
                    ler termos
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </Flex>
        </GridItem> */}
      </Grid>
      <Terms isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Home
