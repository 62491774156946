import React, { useState, useRef, useContext } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
} from '@chakra-ui/react'
import { simplificaApi } from 'services'
import { format, parseISO } from 'date-fns/esm'
import pad from 'utils/pad'
import Logo from '../../../assets/logo-v2.png'
import { ExportModalData } from '..'
import { AuthContext } from 'context/AuthContext'

interface EmailModalProps {
  isOpen: boolean
  onClose: () => void
  data: ExportModalData | null
}

const TIME_TO_RESEND = 90
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

function EmailModal({ isOpen, onClose, data }: EmailModalProps): JSX.Element {
  if (!data) return <></>

  const { billet, client, installmentDueDate, installmentValue } = data
  const [secondsToResend, setSecondsToResend] = useState(TIME_TO_RESEND)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const toast = useToast()
  const interval = useRef<any>(null)
  const [emailIsInvalid, setEmailIsInvalid] = useState(false)
  const { user } = useContext(AuthContext)

  const handleSendEmail = async () => {
    if (!user) return
    if (!EMAIL_REGEX.test(email)) {
      setEmailIsInvalid(true)
      return
    }

    setIsLoading(true)

    try {
      const capitalizedClientName =
        client.at(0)?.toUpperCase() + client.slice(1)

      await simplificaApi.post('/share/email', {
        customer: client,
        mailPayload: {
          sender: {
            name: `${capitalizedClientName} - simplifica.ai`,
            logo: `${process.env.REACT_APP_FRONTEND}${Logo}`,
          },
          destination: {
            name: user.name,
            email,
          },
          content: {
            template: 'boleto',
            templateValues: {
              digitableLine: !billet.pdf && billet.digitableLine,
            },
            subject: `Boleto acordo ${capitalizedClientName}`,
            dueDate: format(
              typeof installmentDueDate === 'number'
                ? installmentDueDate
                : parseISO(installmentDueDate as string),
              'dd/MM/yyyy'
            ),
            value: installmentValue.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
            attachments: billet.pdf ? [billet.pdf] : [],
          },
        },
      })
      if (secondsToResend < TIME_TO_RESEND) {
        setSecondsToResend(TIME_TO_RESEND)
      }
      onClose()
      toast({
        title: `Email enviado para ${email}`,
        description: 'Caso não encontre o email, verifique a caixa de spam',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      interval.current = setInterval(() => {
        if (secondsToResend > 0) {
          setSecondsToResend((state) => state - 1)
        } else {
          clearInterval(interval.current)
        }
      }, 1000)
    } catch (error) {
      toast({
        title: `Erro ao enviar email`,
        description: 'Ocorreu um erro inesperado',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    setIsLoading(false)
  }

  const isWaitingToResend =
    secondsToResend !== TIME_TO_RESEND && secondsToResend > 0

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent marginX={['1rem', '1rem']}>
        <ModalHeader color="primary.main" fontSize="lg">
          Email para receber boleto
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="1rem">
          <Input
            w="100%"
            variant="filled"
            placeholder="seuemail@domínio.com.br"
            borderRadius="10px"
            onChange={(e) => {
              setEmail(e.target.value)
              if (emailIsInvalid) setEmailIsInvalid(false)
            }}
            isInvalid={emailIsInvalid}
          />
          {emailIsInvalid && (
            <Text fontSize="xs" color="red">
              Email inválido
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="primary.main"
            _hover={{ bg: 'primary.hover' }}
            _active={{ bg: 'primary.hover' }}
            color="#fff"
            borderRadius="10px"
            isLoading={isLoading}
            onClick={handleSendEmail}
            disabled={isWaitingToResend || isLoading}
          >
            {isWaitingToResend ? (
              <>
                reenviar em{' '}
                {pad(String(parseInt(String(secondsToResend / 60))), 2, 'left')}
                :
                {pad(String(parseInt(String(secondsToResend % 60))), 2, 'left')}
              </>
            ) : (
              'enviar'
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EmailModal
