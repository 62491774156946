import React from 'react'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react'

const AgreementItemSkeleton: React.FC = () => {
  return (
    <Grid
      w="100%"
      align="center"
      mb="0.625rem"
      p="1.275rem"
      backgroundColor="#fff"
      borderRadius="10px"
      boxShadow=" 0 0 15px 2px #00000010"
      gridTemplateRows="max-content max-content "
      gridTemplateColumns="calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5)"
      gridRowGap="0.625rem"
    >
      <GridItem>
        <Skeleton height="14px" width="35px" />
      </GridItem>
      <GridItem>
        <Skeleton height="14px" width="40px" />
      </GridItem>
      <GridItem>
        <Skeleton height="14px" width="50px" />
      </GridItem>
      <GridItem>
        <Skeleton height="14px" width="50px" />
      </GridItem>
      <GridItem>
        <Skeleton height="14px" width="25px" />
      </GridItem>
      <GridItem>
        <Flex w="100%" h="100%" justify="center" align="center">
          <Skeleton height="16px" width="50px" />
        </Flex>
      </GridItem>
      <GridItem>
        <Flex w="100%" h="100%" justify="center" align="center">
          <SkeletonCircle size="50" />
        </Flex>
      </GridItem>
      <GridItem>
        <Flex w="100%" h="100%" justify="center" align="center">
          <Skeleton height="16px" width="80px" />
        </Flex>
      </GridItem>
      <GridItem>
        <Flex w="100%" h="100%" justify="center" align="center">
          <Skeleton height="14px" width="50px" />
        </Flex>
      </GridItem>
      <GridItem>
        <Flex w="100%" h="100%" justify="center" align="center">
          <SkeletonCircle size="5" />
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default AgreementItemSkeleton
