import React, { useEffect } from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import OneSignal from 'react-onesignal'
import Routes from './routes'
import { DebtProvider } from './context/DebtContext'
import { ErrorBoundary } from 'react-error-boundary'
import UiErrorBoundary from './components/UiErrorBoundary'
import { errorHandler } from './utils/errorHandler'
import { AuthProvider } from 'context/AuthContext'

export const App = (): JSX.Element => {
  const theme = extendTheme({
    colors: {
      primary: {
        main: '#333',
        hover: '#333333cc',
        hover40: '#33333366',
        hover20: '#33333333',
      },
      secondary: '#848484',
      subtitle: '#b8b8b8',
    },
    styles: {
      global: {
        'html, body': {
          fontFamily:
            '"Poppins", -apple-system, BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        },
      },
    },
  })

  // useEffect(() => {
  //   OneSignal.init({
  //     // 7ce3ad8b-31dc-44d1-81e4-f6181ca4b706
  //     appId: process.env.REACT_APP_ONESIGNAL_APP_ID as string,
  //   })
  // }, [])

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary FallbackComponent={UiErrorBoundary} onError={errorHandler}>
        <AuthProvider>
          <DebtProvider>
            <Routes />
          </DebtProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ChakraProvider>
  )
}
