import {
  Box,
  Button,
  ChakraProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'

interface ModalSelectOption {
  installments: number
  value: number
  otherInstallments: number
}

interface ModalSelect extends ChakraProps {
  label: string
  options: ModalSelectOption[]
  onSelect?: (option: ModalSelectOption) => void
  defaultValue?: ModalSelectOption
  placeholder?: string
  value: ModalSelectOption | null
  isLoading?: boolean
  disabled?: boolean
}

function ModalSelect({
  label,
  options,
  onSelect,
  defaultValue,
  placeholder,
  disabled,
  isLoading,
  ...props
}: ModalSelect): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [value, setValue] = useState<ModalSelectOption | null>(
    options.find(
      (opt) => props.value && opt.installments === props.value.installments
    ) || null
  )
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (buttonRef.current && !isLoading) {
      buttonRef.current.focus()
      onOpen()
    }
  }, [buttonRef.current, isLoading])

  useEffect(() => {
    if (!options || options.length < 1) return
    setValue(options[0])
    if (onSelect) onSelect(options[0])
  }, [options])

  return (
    <>
      <Box {...props}>
        <Text fontSize="sm">{label}</Text>
        <Button
          cursor="pointer"
          w="100%"
          justifyContent="left"
          color="primary.main"
          onClick={onOpen}
          variant="outline"
          paddingX="0.75rem"
          fontWeight="500"
          disabled={disabled}
          isTruncated
          data-testid="button-select"
          ref={buttonRef}
        >
          {isLoading && <Spinner color="gray.500" mr="10px" size="sm" />}
          {!value && defaultValue
            ? `${
                defaultValue.installments
              }x de ${defaultValue.value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}`
            : value
            ? !value.otherInstallments
              ? `${value.installments}x de ${value.value.toLocaleString(
                  'pt-BR',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  }
                )}`
              : `Entrada ${value.value.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })} + ${
                  value.installments - 1
                }x ${value.otherInstallments.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}`
            : placeholder || 'Selecione uma opção'}
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent marginX={['1rem', '1rem']} p={['0.5rem', '1rem']}>
          <ModalHeader color="primary.main" fontSize="lg">
            Opções de parcelamento
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="1rem">
            {options.length > 0 ? (
              options.map((option, index) => (
                <Button
                  key={option.installments}
                  w="100%"
                  color="primary.main"
                  onClick={() => {
                    const opt = options.find(
                      (opt) => opt.installments === option.installments
                    )
                    setValue(opt || null)
                    if (onSelect) onSelect(option as ModalSelectOption)
                    onClose()
                  }}
                  variant="outline"
                  mb="10px"
                  height="auto"
                  justifyContent="left"
                  p="0.75rem"
                  pl="1rem"
                  fontWeight="500"
                  display="flex"
                  alignItems="center"
                >
                  <Text
                    fontSize="md"
                    color="#9e9d9d"
                    fontWeight="600"
                    mr="1rem"
                    w="1rem"
                  >
                    {option.installments}x
                  </Text>
                  <Box
                    w="100%"
                    display="flex"
                    flexDir="column"
                    alignItems="flex-start"
                  >
                    {option.otherInstallments ? (
                      <>
                        <Text fontSize="sm" color="#333" fontWeight="300">
                          Entrada no valor de{' '}
                          {option.value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Text>
                        <Text>
                          mais {option.installments - 1} parcela
                          {option.installments - 1 > 1 ? 's' : ''} de{' '}
                          {option.otherInstallments.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text>
                          {option.value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Text>
                      </>
                    )}
                  </Box>
                </Button>
              ))
            ) : (
              <Text>nenhuma opção</Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalSelect
