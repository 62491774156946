import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'
import React, { useRef } from 'react'
import { FiChevronDown, FiDownload } from 'react-icons/fi'
import { Agreement } from '.'
import Algar from '../../assets/algar-logo.svg'
import Eudora from '../../assets/eudora-logo.svg'
import oBoticario from '../../assets/o-boticario-logo.svg'
import Viacerta from '../../assets/viacerta-logo.svg'
import Natura from '../../assets/natura-logo.svg'

interface AgreementProps {
  agreement: Agreement
  onOpenExportModal: (agreement: Agreement, installmentId: number) => void
}

function AgreementItem({
  onOpenExportModal,
  agreement,
}: AgreementProps): JSX.Element {
  const [isShorterThan600px, isShorterThan340px] = useMediaQuery([
    '(max-width: 600px)',
    '(max-width: 340px)',
  ])
  const btnRef = useRef<HTMLButtonElement>(null)

  return (
    <AccordionItem w="100%" border="none" position="relative">
      {isShorterThan600px && (
        <Icon
          as={FiChevronDown}
          cursor="pointer"
          position="absolute"
          transform="translateX(-50%)"
          left="50%"
          bottom="0.3rem"
          onClick={() =>
            isShorterThan600px && btnRef.current && btnRef.current.click()
          }
        />
      )}
      <Grid
        w="100%"
        align="center"
        mb="0.625rem"
        p="1.275rem"
        pb="1.5rem"
        backgroundColor="#fff"
        borderRadius="10px"
        boxShadow=" 0 0 15px 2px #00000010"
        gridTemplateRows="max-content max-content max-content"
        gridTemplateColumns={
          isShorterThan600px
            ? 'calc(100% / 3) calc(100% / 3) calc(100% / 3)'
            : 'calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5) calc(100% / 5)'
        }
        gridRowGap="0.625rem"
        onClick={() =>
          isShorterThan600px && btnRef.current && btnRef.current.click()
        }
      >
        {!isShorterThan600px && (
          <GridItem>
            <Text fontSize="0.775rem" fontWeight="bold" color="primary.main">
              código
            </Text>
          </GridItem>
        )}
        <GridItem>
          <Text fontSize="0.775rem" fontWeight="bold" color="primary.main">
            carteira
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="0.775rem" fontWeight="bold" color="primary.main">
            parcelas
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="0.775rem" fontWeight="bold" color="primary.main">
            data do acordo
          </Text>
        </GridItem>
        {!isShorterThan600px && (
          <GridItem>
            <Text fontSize="0.775rem" fontWeight="bold" color="primary.main">
              ações
            </Text>
          </GridItem>
        )}
        {!isShorterThan600px && (
          <GridItem>
            <Flex w="100%" h="100%" justify="center" align="center">
              <Text color="primary.main">{agreement.code}</Text>
            </Flex>
          </GridItem>
        )}
        <GridItem>
          <Flex w="100%" h="100%" justify="center" align="center">
            {agreement.client === 'natura' && (
              <Image
                src={Natura}
                w="100%"
                maxW="60px"
                objectFit="contain"
                alt="natura"
              />
            )}
            {agreement.client === 'boticario' && (
              <Image
                src={oBoticario}
                w="100%"
                maxW="90px"
                objectFit="contain"
                alt="oboticario"
              />
            )}
            {agreement.client === 'algar' && (
              <Image
                src={Algar}
                alt="algar"
                w="100%"
                maxW="70px"
                objectFit="contain"
              />
            )}
            {agreement.client === 'eudora' && (
              <Image
                src={Eudora}
                alt="eudora"
                w="100%"
                maxW="50px"
                objectFit="contain"
              />
            )}
            {agreement.client === 'viacerta' && (
              <Image
                src={Viacerta}
                alt="viacerta"
                w="100%"
                maxW="90px"
                objectFit="contain"
              />
            )}
          </Flex>
        </GridItem>
        <GridItem>
          <Flex w="100%" h="100%" justify="center" align="center">
            <Text color="primary.main">{agreement.totalInstallments}</Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex w="100%" h="100%" justify="center" align="center">
            <Text color="primary.main">
              {format(parseISO(agreement.agreementDate), 'dd/MM/yyyy')}
            </Text>
          </Flex>
        </GridItem>

        <GridItem display={!isShorterThan600px ? 'block' : 'none'}>
          <Flex w="100%" h="100%" justify="center" align="center">
            <AccordionButton maxW="min-content" ref={btnRef}>
              <Icon as={FiChevronDown} cursor="pointer" />
            </AccordionButton>
          </Flex>
        </GridItem>

        <GridItem
          colSpan={isShorterThan600px ? 3 : 5}
          onClick={(e) => e.stopPropagation()}
        >
          <AccordionPanel w="100%" pt="1.5rem" px="0" pb="0.625rem">
            <Flex
              w="100%"
              flexDirection="column"
              paddingY="2rem"
              background="#f7f7f7"
              borderRadius="10px"
              px="1rem"
            >
              <Text fontSize="2xl" fontWeight="bold" color="primary.main">
                Parcelas
              </Text>
              <Text fontSize="md" mb="2rem" color="#979797">
                Aqui você pode adiantar quantas parcelas quiser!
              </Text>
              {/* {!isShorterThan600px && (
                <TableContainer>
                  <Table variant="striped">
                    <TableCaption>
                      Parcelas disponíveis para emissão de segunda via
                    </TableCaption>
                    <Thead>
                      <Tr>
                        <Th isNumeric>nº da Parcela</Th>
                        <Th>vencimento</Th>
                        <Th isNumeric>valor</Th>
                        <Th>ações</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {agreement.agreementInstallments.map(
                        (installment: AgreementInstallment) => (
                          <Tr key={installment.id}>
                            <Td isNumeric>{installment.installments}</Td>
                            <Td>
                              {format(
                                parseISO(installment.dueDate),
                                'dd/MM/yyyy'
                              )}
                            </Td>

                            <Td isNumeric>
                              {installment.value.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Td>
                            <Td>
                              <Tooltip label="Gerar boleto">
                                <span>
                                  <Icon
                                    as={TbFileBarcode}
                                    cursor="pointer"
                                    onClick={() =>
                                      onOpenExportModal(
                                        agreement,
                                        installment.id
                                      )
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                    <Tfoot>
                      <Tr>
                        <Th isNumeric>nº da Parcela</Th>
                        <Th>vencimento</Th>
                        <Th isNumeric>valor</Th>
                        <Th>ações</Th>
                      </Tr>
                    </Tfoot>
                  </Table>
                </TableContainer>
              )} */}
              {agreement.agreementInstallments.map((installment) => (
                <Grid
                  key={installment.id}
                  gridTemplateRows="max-content max-content"
                  gridTemplateColumns={
                    isShorterThan600px
                      ? 'calc(100% / 2 - 0.417rem - 10px) calc(100% / 2 - 0.417rem - 10px) max-content'
                      : 'calc(100% / 3 - 0.615rem - 7px) calc(100% / 3 - 0.615rem - 7px) calc(100% / 3 - 0.615rem - 7px) max-content'
                  }
                  gap="0.625rem"
                  marginBottom="1rem"
                  background="#fff"
                  py="1rem"
                  px="1rem"
                  borderRadius="10px"
                  border="1px solid #0000001a"
                  justifyContent="center"
                >
                  {!isShorterThan600px && (
                    <GridItem>
                      <Text
                        fontSize="0.775rem"
                        fontWeight="bold"
                        color="primary.main"
                      >
                        nº parcela
                      </Text>
                    </GridItem>
                  )}
                  <GridItem>
                    <Text
                      fontSize="0.775rem"
                      fontWeight="bold"
                      color="primary.main"
                    >
                      vencimento
                    </Text>
                  </GridItem>
                  {!isShorterThan340px && (
                    <GridItem>
                      <Text
                        fontSize="0.775rem"
                        fontWeight="bold"
                        color="primary.main"
                      >
                        valor
                      </Text>
                    </GridItem>
                  )}
                  <GridItem rowSpan={2}>
                    <Flex w="100%" h="100%" justify="center" align="center">
                      <Tooltip label="Gerar boleto">
                        <span>
                          <Icon
                            as={FiDownload}
                            cursor="pointer"
                            fontSize="1.25rem"
                            onClick={() =>
                              onOpenExportModal(agreement, installment.id)
                            }
                            color="#333"
                          />
                        </span>
                      </Tooltip>
                    </Flex>
                  </GridItem>
                  {!isShorterThan600px && (
                    <GridItem>
                      <Flex w="100%" h="100%" justify="center" align="center">
                        <Text color="primary.main">
                          {installment.installments}
                        </Text>
                      </Flex>
                    </GridItem>
                  )}
                  <GridItem>
                    <Flex w="100%" h="100%" justify="center" align="center">
                      <Text color="primary.main">
                        {format(parseISO(installment.dueDate), 'dd/MM/yyyy')}
                      </Text>
                    </Flex>
                  </GridItem>
                  {!isShorterThan340px && (
                    <GridItem>
                      <Flex w="100%" h="100%" justify="center" align="center">
                        <Text color="primary.main">
                          {installment.value.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Text>
                      </Flex>
                    </GridItem>
                  )}
                </Grid>
              ))}
            </Flex>
          </AccordionPanel>
        </GridItem>
      </Grid>
    </AccordionItem>
  )
}

export default AgreementItem
