import axios from 'axios'
import { getToken } from '../utils/authUtils'

const vyaApi = axios.create({
  baseURL: process.env.REACT_APP_VYA_DIGITAL_API,
  timeout: 180 /* seconds */ * 1000,
})

const billetApi = axios.create({
  baseURL: process.env.REACT_APP_BILLET_API_URL,
})

const bradescoApi = axios.create({
  baseURL: process.env.REACT_APP_BRADESCO_API_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_BRADESCO_API_KEY,
  },
})

const simplificaApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
})

simplificaApi.interceptors.request.use((config) => {
  if (!config.headers.authorization) {
    config.headers.authorization = `Bearer ${getToken()}`
  }

  return config
})

export { vyaApi, billetApi, bradescoApi, simplificaApi }
