import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  VStack,
  SlideFade,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { useContext, useState, useEffect } from 'react'

import {
  FiCopy,
  FiDownload,
  FiMail,
  FiMessageSquare,
  FiShare2,
} from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import EmailModal from '../../../components/EmailModal'
import SmsModal from '../../../components/SmsModal'
import useShare from 'use-share'
import { DebtContext } from '../../../context/DebtContext'
import { format, isThisWeek, parseISO } from 'date-fns'
import ptBR from 'date-fns/esm/locale/pt-BR/index.js'
import { sendAnalytics } from '../../../utils/sendAnalytics'
import Toggle from 'components/ContractModal/Toggle'
import { StepComponentProps } from '.'
import { AuthContext } from 'context/AuthContext'

function Payment({ customer }: StepComponentProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenSMS,
    onOpen: onOpenSMS,
    onClose: onCloseSMS,
  } = useDisclosure()
  const [isExporting, setIsExporting] = useState(false)
  const { billet, paymentOption, debt, handleEnd, statusCode, demoMode } =
    useContext(DebtContext)
  const { user } = useContext(AuthContext)
  const { share } = useShare({
    title: `Meu boleto do simplifica.ai acordo ${debt?.client}`,
    text: `Linha Digitável: ${billet.digitableLine}${
      billet.pdf ? `\n\rLink para boleto: ${billet.pdf}\n\r` : '\n\r'
    }`,
    url: 'https://simplifica.ai',
  })

  const { onCopy, hasCopied } = useClipboard(
    billet.digitableLine as string,
    20000
  )
  const toast = useToast()
  const history = useHistory()
  const data = debt?.data as any
  const dueDate =
    typeof debt?.dueDate === 'number'
      ? debt?.dueDate
      : parseISO(debt?.dueDate as string)

  const downloadBillet = async () => {
    if (!billet.pdf || !user) return
    setIsExporting(true)
    const response = await fetch(billet.pdf as string)
    const blob = await response.blob()

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `boleto${debt ? `-${debt?.client}` : ''}-${format(
      dueDate,
      'yyyy-MM-dd'
    )}.pdf`
    a.click()
    setIsExporting(false)
    sendAnalytics({
      id: user.id,
      actionId: user.actionId,
      step: 'EXPORTAR - DOWNLOAD',
      status: 'EXPORTAR - DOWNLOAD',
      statusCode,
      isDemo: demoMode,
    })
  }

  useEffect(() => {
    if (!user) return

    downloadBillet()
    sendAnalytics({
      id: user.id,
      actionId: user.actionId,
      step: 'PAGAMENTO STEP',
      status: 'OK',
      statusCode,
      isDemo: demoMode,
    })
  }, [])

  return (
    <>
      <SlideFade in={true}>
        <Box height="100%">
          <Text
            as="h1"
            fontSize="2xl"
            fontWeight="bold"
            color="primary.main"
            mb="2rem"
          >
            Pagamento
          </Text>
          <Flex direction="column">
            {/* <Flex flexDirection="column" alignItems="center">
              <Image src={Logo} w="80px" objectFit="contain" mb="10px" />
              <Flex
                p={isShorterThan620px ? '8px' : '10px'}
                bgImage={`url(${QrCodeWrapper})`}
              >
                <QRCode
                  value={
                    billet.pdf
                      ? (billet.pdf as string)
                      : (billet.digitableLine as string)
                  }
                  bgColor="#fff"
                  fgColor="#333"
                  level="M"
                  size={isShorterThan620px ? 100 : 120}
                  includeMargin={false}
                  renderAs="svg"
                  imageSettings={
                    isShorterThan620px
                      ? undefined
                      : {
                          src: QrCodeLogo,
                          x: undefined,
                          y: undefined,
                          height: isShorterThan620px ? 16 : 24,
                          width: isShorterThan620px ? 16 : 24,
                          excavate: true,
                        }
                  }
                />
              </Flex>
            </Flex> */}
            <Flex
              flexDirection="column"
              // pl={['1rem', '1.5rem']}
              my={['2rem', '0']}
            >
              <Text color="secondary" fontSize="sm">
                {paymentOption?.installments &&
                  (!paymentOption.otherInstallments
                    ? `${
                        paymentOption.installments
                      }x de ${paymentOption.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}`
                    : `Entrada ${paymentOption.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })} + ${
                        paymentOption.installments - 1
                      }x de ${paymentOption.otherInstallments.toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}`)}
              </Text>
              {debt && (
                <HStack spacing="5px">
                  <Text color="secondary" fontSize="sm">
                    {/* {debt.qtyContracts} contrato{debt.qtyContracts > 1 && 's'} */}
                    {debt.contracts.length} título
                    {debt.contracts.length > 1 && 's'}
                  </Text>
                  <Toggle
                    color="secondary"
                    fontSize="sm"
                    id={data.id}
                    debt={debt}
                  />
                </HStack>
              )}
              <Text color="secondary" fontSize="sm" mb="2rem">
                Vencimento em{' '}
                {format(
                  dueDate,
                  `dd/MM/yyyy${isThisWeek(dueDate) ? ' (eee)' : ''}`,
                  {
                    locale: ptBR,
                  }
                )}
              </Text>
              <VStack spacing="1rem" align="flex-start">
                <HStack
                  _hover={{
                    opacity: 0.8,
                  }}
                  as="button"
                  onClick={() => {
                    onCopy()
                    if (user) {
                      sendAnalytics({
                        id: user.id,
                        actionId: user?.actionId,
                        step: 'EXPORTAR - COPIAR LINHA DIGITÁVEL',
                        status: 'EXPORTAR - COPIAR LINHA DIGITÁVEL',
                        statusCode,
                        isDemo: demoMode,
                      })
                    }
                    toast({
                      description: 'Linha digitável copiada!',
                      status: 'success',
                      duration: 9000,
                      isClosable: true,
                    })
                  }}
                >
                  <Icon
                    as={FiCopy}
                    color="primary.main"
                    fontSize="xl"
                    cursor="pointer"
                  />
                  <Text fontSize="sm">
                    {hasCopied
                      ? 'Linha digitável copiada!'
                      : 'Copiar linha digitável'}
                  </Text>
                </HStack>
                <HStack
                  _hover={{
                    opacity: 0.8,
                  }}
                  as="button"
                  onClick={!isExporting ? downloadBillet : undefined}
                  opacity={isExporting ? 0.5 : 1}
                >
                  <Icon
                    as={FiDownload}
                    color="primary.main"
                    fontSize="xl"
                    cursor="pointer"
                  />
                  <Text fontSize="sm">Baixar boleto</Text>
                </HStack>
                <HStack
                  _hover={{
                    opacity: 0.8,
                  }}
                  as="button"
                  onClick={() => {
                    share()
                    if (user) {
                      sendAnalytics({
                        id: user.id,
                        actionId: user.actionId,
                        step: 'EXPORTAR - COMPARTILHAR LINHA DIGITÁVEL',
                        status: 'EXPORTAR - COMPARTILHAR LINHA DIGITÁVEL',
                        statusCode,
                        isDemo: demoMode,
                      })
                    }
                  }}
                >
                  <Icon
                    as={FiShare2}
                    color="primary.main"
                    fontSize="xl"
                    cursor="pointer"
                  />
                  <Text fontSize="sm">Compartilhar</Text>
                </HStack>
                <HStack
                  _hover={{
                    opacity: 0.8,
                  }}
                  as="button"
                  onClick={onOpen}
                  data-testid="payment-send-by-mail"
                >
                  <Icon
                    as={FiMail}
                    color="primary.main"
                    fontSize="xl"
                    cursor="pointer"
                  />
                  <Text fontSize="sm">Receber por email</Text>
                </HStack>
                <HStack
                  _hover={{
                    opacity: 0.8,
                  }}
                  as="button"
                  onClick={onOpenSMS}
                  data-testid="payment-send-by-sms"
                >
                  <Icon
                    as={FiMessageSquare}
                    color="primary.main"
                    fontSize="xl"
                    cursor="pointer"
                  />
                  <Text fontSize="sm">Receber por SMS</Text>
                </HStack>
              </VStack>
            </Flex>
          </Flex>
        </Box>
      </SlideFade>
      <Flex w="100%" justifyContent="flex-end">
        <Button
          bg="primary.main"
          _hover={{ bg: 'primary.hover' }}
          _active={{ bg: 'primary.hover' }}
          color="#fff"
          borderRadius="10px"
          onClick={() => handleEnd(history, customer)}
        >
          concluir
        </Button>
      </Flex>
      <EmailModal isOpen={isOpen} onClose={onClose} />
      <SmsModal isOpen={isOpenSMS} onClose={onCloseSMS} />
    </>
  )
}

export default Payment
