import React from 'react'
import { Box, Flex, HStack, Skeleton, SkeletonCircle } from '@chakra-ui/react'
// import { Container } from './styles';

const DebtCardSkeleton: React.FC = () => {
  return (
    <Flex
      w={['100%', '100%', 'calc(100% / 2 - 1.5rem)', 'calc(100% / 3 - 1.5rem)']}
      // minW="280px"
      bg="white"
      borderRadius="10px"
      p="2rem"
      mr={['0rem', '1.5rem']}
      mb="2rem"
      flexDirection="column"
      justifyContent="space-between"
      boxShadow=" 0 0 20px 10px #00000010"
    >
      <Box>
        <HStack justifyContent="space-between">
          <Skeleton height="40px" width="60%" />
          <SkeletonCircle size="12" />
        </HStack>
        <Box py="2rem">
          <Skeleton height="14px" width="50%" mb="0.5rem" />
          <HStack>
            <Skeleton height="14px" width="25px" />
            <Skeleton height="40px" width="150px" mb="2rem" />
            <Skeleton height="14px" width="40px" />
          </HStack>
        </Box>
      </Box>
      <HStack>
        <Skeleton height="40px" width="130px" borderRadius="10px" mr="10px" />
        <SkeletonCircle size="10" />
      </HStack>
    </Flex>
  )
}

export default DebtCardSkeleton
