import React, { useState, useRef, useEffect, useContext } from 'react'
import {
  Button,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  InputLeftElement,
  Image,
  Tooltip,
  Text,
} from '@chakra-ui/react'
import { mask, unMask } from 'utils/remask'
import BrFlag from '../../../assets/br.svg'
import { simplificaApi } from 'services'
import pad from 'utils/pad'
import { DebtContext } from 'context/DebtContext'
import { ExportModalData } from '..'
import { AuthContext } from 'context/AuthContext'
interface SmsModalProps {
  isOpen: boolean
  onClose: () => void
  data: ExportModalData | null
}

const TIME_TO_RESEND = 90
const PHONE_NUMBER_REGEX = /^[0-9]{2}9[0-9]{8}$/

function SmsModal({ isOpen, onClose, data }: SmsModalProps): JSX.Element {
  if (!data) return <></>

  const { client, billet } = data
  const [isLoading, setIsLoading] = useState(false)
  const [secondsToResend, setSecondsToResend] = useState(TIME_TO_RESEND)
  const [sms, setSMS] = useState('')
  const { user } = useContext(AuthContext)
  const toast = useToast()
  const interval = useRef<any>(null)
  const [phoneIsInvalid, setPhoneIsInvalid] = useState(false)

  const handleSendSMS = async () => {
    if (!user) return
    const unmaskPhone = unMask(sms)
    if (!PHONE_NUMBER_REGEX.test(unmaskPhone)) {
      setPhoneIsInvalid(true)
      return
    }
    setIsLoading(true)

    try {
      await simplificaApi.post('/share/sms', {
        customer: client,
        smsPayload: {
          name: user?.name,
          digitableLine: billet.digitableLine,
          phone: unmaskPhone,
          id: user.id,
          actionId: user.actionId,
        },
      })

      if (secondsToResend < TIME_TO_RESEND) {
        setSecondsToResend(TIME_TO_RESEND)
      }
      onClose()
      toast({
        title: `SMS enviado para ${sms}`,
        description:
          'Aguarde alguns segundos e você receberá informações do seu boleto',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      interval.current = setInterval(() => {
        if (secondsToResend > 0) {
          setSecondsToResend((state) => state - 1)
        } else {
          clearInterval(interval.current)
        }
      }, 1000)
    } catch (error) {
      toast({
        title: `Erro ao enviar SMS`,
        description: (error as any).message as string,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => () => clearInterval(interval.current), [])
  const isWaitingToResend =
    secondsToResend !== TIME_TO_RESEND && secondsToResend > 0
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent marginX={['1rem', '1rem']}>
        <ModalHeader color="primary.main" fontSize="lg">
          Celular para receber boleto
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="1rem">
          <InputGroup>
            <InputLeftElement>
              <Tooltip label="+55" bg="primary.main" borderRadius="10px">
                <Image
                  src={BrFlag}
                  w="25px"
                  borderRadius="5px"
                  alt="bandeira do brasil"
                />
              </Tooltip>
            </InputLeftElement>
            <Input
              w="100%"
              variant="filled"
              placeholder="(99) 99999-9999"
              borderRadius="10px"
              value={sms}
              onChange={(e) => {
                setSMS(mask(e.target.value, '(99) 99999-9999'))
                if (phoneIsInvalid) setPhoneIsInvalid(false)
              }}
              isInvalid={phoneIsInvalid}
            />
          </InputGroup>
          {phoneIsInvalid && (
            <Text fontSize="xs" color="red">
              Celular inválido
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="primary.main"
            _hover={{ bg: 'primary.hover' }}
            _active={{ bg: 'primary.hover' }}
            color="#fff"
            borderRadius="10px"
            isLoading={isLoading}
            onClick={handleSendSMS}
            disabled={isWaitingToResend || isLoading}
          >
            {isWaitingToResend ? (
              <>
                reenviar em{' '}
                {pad(String(parseInt(String(secondsToResend / 60))), 2, 'left')}
                :
                {pad(String(parseInt(String(secondsToResend % 60))), 2, 'left')}
              </>
            ) : (
              'enviar'
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SmsModal
