import React, { useContext, useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  useMediaQuery,
  HStack,
  Tooltip,
  Spinner,
} from '@chakra-ui/react'
import Wizard from './Wizard'
import { DebtContext } from 'context/DebtContext'
import { mask } from 'utils/remask'
import { RouteComponentProps } from 'react-router'
import { FiChevronLeft } from 'react-icons/fi'
import { sendAnalytics } from 'utils/sendAnalytics'
import Navbar from 'components/Navbar'
import { getToken } from 'utils/authUtils'
import { AuthContext } from 'context/AuthContext'

interface MyDebtsProps extends RouteComponentProps {
  customer?: string
}

function Debt({ history, customer }: MyDebtsProps): JSX.Element {
  const [isShorterThan1380px, isShorterThan620px] = useMediaQuery([
    '(max-width: 1380px)',
    '(max-width: 620px)',
  ])
  const { debt, statusCode, demoMode } = useContext(DebtContext)
  const { user, isLoading } = useContext(AuthContext)

  useEffect(() => {
    const token = getToken()
    if (!token) {
      history.replace('/search-debts')
    }
  }, [])

  return (
    <Flex
      w="100%"
      minH="100vh"
      alignItems="flex-start"
      justifyContent="center"
      position="relative"
      p="1rem"
      pt="calc(1rem + 80px)"
    >
      <Navbar whiteText />
      <Box
        w="100%"
        h="100vh"
        position="fixed"
        top="0"
        left="0"
        overflow="hidden"
        zIndex="-1"
      >
        <Box
          h="100%"
          w="150%"
          position="absolute"
          top="-30%"
          left="-20%"
          background="linear-gradient(0deg, #404040, #333 100%)"
          borderRadius="0 0 50px 0"
          transform="rotate(3deg)"
          zIndex="-1"
        />
      </Box>

      <Flex
        w="100%"
        maxW={['100%', '100%', '90%', isShorterThan1380px ? '90%' : '70%']}
        flexDirection="column"
        zIndex="1"
      >
        <HStack mb="1rem">
          <FiChevronLeft
            color="white"
            cursor="pointer"
            onClick={() => {
              history.goBack()
              if (user) {
                sendAnalytics({
                  id: user.id,
                  actionId: user.actionId,
                  step: 'VOLTAR PARA DÍVIDAS',
                  status: 'OK',
                  statusCode,
                  isDemo: demoMode,
                })
              }
            }}
          />
          <Text
            color="white"
            cursor="pointer"
            onClick={() => {
              history.goBack()
              if (user) {
                sendAnalytics({
                  id: user.id,
                  actionId: user.actionId,
                  step: 'VOLTAR PARA DÍVIDAS',
                  status: 'OK',
                  statusCode,
                  isDemo: demoMode,
                })
              }
            }}
            fontSize={['sm', 'md']}
          >
            voltar
          </Text>
        </HStack>
        {isLoading ? (
          <HStack>
            <Spinner color="#b8b8b8" size="sm" />
            <Text color="#b8b8b8" fontSize={['sm', 'md']}>
              Carregando...
            </Text>
          </HStack>
        ) : (
          <Tooltip
            label={user?.name}
            bg="primary.main"
            borderRadius="10px"
            placement="bottom-start"
          >
            <span>
              <Text color="#b8b8b8" fontSize={['sm', 'md']}>
                {user?.name &&
                  user?.name !== ' ' &&
                  (() => {
                    const userName = user?.name?.toUpperCase().split(' ')

                    return `${userName[0]} ${userName[1] ? userName[1] : ''}${
                      userName.length > 1 ? '...' : ''
                    }
                    - `
                  })()}
                CPF{' '}
                {user?.cpf &&
                  (() => {
                    if (user?.cpf && user?.cpf.length <= 11) {
                      return mask(user?.cpf as string, '999.999.999-99')
                    } else {
                      return mask(user?.cpf as string, '99.999.999/9999-99')
                    }
                  })()}
                {!user?.cpf && 'NÃO ENCONTRADO'}
              </Text>
            </span>
          </Tooltip>
        )}
        <Text
          as="h1"
          color="white"
          fontSize={['3xl', '3xl', '4xl', '4xl']}
          fontWeight="700"
          mb="4rem"
        >
          Proposta
        </Text>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <Wizard customer={customer} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Debt
